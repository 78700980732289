import * as React from 'react';
import { IconButton, Tooltip } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TokenIcon from '@mui/icons-material/Token';
import { AppDataContext } from "../utils/app-data-context";

export const chainInfo = {
    avalanche: {
        tracker: "snowtrace",
    },
    arbitrum: {
        tracker: "arbiscan",
    },
};

export const ChainIconButton = (props) => {
    const { chain } = React.useContext(AppDataContext);
    const { address, addressOrToken = 'address', tooltipTitle = (tr) => `View on ${tr}` } = props;
    const { tracker } = chainInfo[chain];
    return (
        <Tooltip title={tooltipTitle(tracker)}>
            <IconButton href={`https://${tracker}.io/${addressOrToken}/${address}`} target="_blank" rel="noreferrer">
                {
                    addressOrToken === 'address'
                        ? <OpenInNewIcon color={chain} fontSize='small' />
                        : <TokenIcon color={chain} fontSize='small' />
                }
            </IconButton>
        </Tooltip>
    );
};
