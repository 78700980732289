import { Card, CardContent, Divider, Grid, Link, Skeleton, Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { currencyRounded, percentageFormat } from "../utils/number-formats";

export const UserPortfolioCard = (props) => {
    const { latestData, loading } = props;
    const { userDepositTokenBalanceUSD, cumulativeNetDepositsUSDAdjusted, cumulativeNetDepositsUSD, simpleReturn, timeWeightedRateOfReturn } = latestData;
    return (
        <Card sx={{ height: '100%' }}>
            <CardContent>
                <Grid container spacing={1} >
                    {
                        [
                            { label: "Portfolio value", labelVariant: "subtitle1", value: currencyRounded(userDepositTokenBalanceUSD), valueVariant: "h4", valueColor: "primary" },
                            { label: "Net Deposits (past value)", value: currencyRounded(cumulativeNetDepositsUSD), info: "Value in USD of the cumulative net deposits at the time of the transaction(s)" },
                            {
                                label: "Returns",
                                value: currencyRounded(userDepositTokenBalanceUSD - cumulativeNetDepositsUSD, true),
                                info: "Overall profit/loss from net deposits (returns from price movements and farming combined)",
                                valueColor: (userDepositTokenBalanceUSD > cumulativeNetDepositsUSD ? "primary" : "error")
                            },
                            { label: "div1" },
                            {
                                label: (cumulativeNetDepositsUSDAdjusted >= cumulativeNetDepositsUSD ? "Gains" : "Losses") + " from price movement",
                                value: currencyRounded(cumulativeNetDepositsUSDAdjusted - cumulativeNetDepositsUSD, true),
                                info: "This shows the underlying gain/loss as a result of price movements and/or impermanent loss of the underlying deposit tokens for each farm",
                                valueColor: (cumulativeNetDepositsUSDAdjusted > cumulativeNetDepositsUSD ? "primary" : "error")
                            },
                            {
                                label: (userDepositTokenBalanceUSD < cumulativeNetDepositsUSDAdjusted ? "Losses" : "Gains") + " from farming",
                                value: currencyRounded(userDepositTokenBalanceUSD - cumulativeNetDepositsUSDAdjusted, true),
                                info: "This shows the present value of the additional tokens gained from farming",
                                valueColor: (userDepositTokenBalanceUSD > cumulativeNetDepositsUSDAdjusted ? "primary" : "error")
                            },
                            { label: "div2" },
                            {
                                label: "Simple return",
                                value: percentageFormat(simpleReturn, true),
                                info: "Gains from farming as a percentage of the present value of the contributions (net deposits)",
                                valueColor: (simpleReturn > 0 ? "primary" : "error")
                            },
                            {
                                label: "TWRR",
                                value: percentageFormat(timeWeightedRateOfReturn, true),
                                info: <Typography variant="tooltip">Time-weighted Rate of Return (TWRR) shows the return from farming once inflows and outflows have been taken into account. For more information, see <Link href="https://www.investopedia.com/terms/t/time-weightedror.asp" target="_blank" rel="noreferrer">here</Link></Typography>,
                                valueColor: (timeWeightedRateOfReturn > 0 ? "primary" : "error")
                            },
                        ].map(
                            ({ label, labelVariant = "subtitle2", value, valueVariant = "h6", valueColor, info }) => (
                                value ?
                                    <Grid item xs={12} key={label} container alignItems="center" justifyContent="flex-start">
                                        <Grid item xs={info ? 6 : 7}>
                                            <Typography variant={labelVariant}>
                                                {label}
                                            </Typography>
                                        </Grid>
                                        {info &&
                                            <Grid item xs={1}>
                                                <Tooltip title={info}>
                                                    <InfoIcon />
                                                </Tooltip>
                                            </Grid>
                                        }
                                        <Grid item xs={5} container justifyContent="flex-end">
                                            <Typography variant={valueVariant} color={valueColor}>
                                                {loading ? <Skeleton width={60} /> : value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    : <Divider key={label} color="primary" sx={{ width: '100%', my: 1 }}></Divider>
                            )
                        )
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}