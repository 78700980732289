
export const currencyRounded = (v, delta = false) => {
    const posPrefix = (delta ? '+' : '');
    return `${Math.sign(v) >= 0 ? posPrefix : '-'}$` + absNumberRounded(v);
};

const absNumberRounded = (n) => {
    const v = Math.abs(n);
    if (v >= 1e12) {
        return `${v.toExponential(2)}`;
    } else if (v >= 1e10) {
        return `${Math.round(v / 1e9)}B`;
    } else if (v >= 1e9) {
        return `${(v / 1e9).toFixed(2)}B`;
    } else if (v >= 1e7) {
        return `${Math.round(v / 1e6)}M`;
    } else if (v >= 1e6) {
        return `${(v / 1e6).toFixed(1)}M`;
    } else if (v >= 1e4) {
        return `${Math.round(v / 1e3)}K`;
    } else if (v >= 1e3) {
        return `${(v / 1e3).toFixed(1)}K`;
    } else if (v >= 1e2) {
        return `${Math.round(v)}`;
    } else if (v >= 0.01) {
        return `${v.toFixed(2)}`;
    } else if (v === 0) {
        return `${Math.round(v)}`;
    } else {
        return `<0.01`;
    }
};

export const integerWithCommas = v => Math.round(v).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
export const percentageFormat = (v, delta = false) => (delta && v >= 0 ? '+' : '') + (v >= 100 ? `${(100 * v).toExponential(1)}%` : (v >= 1 ? `${Math.round(100 * v)}%` : `${(100 * v).toFixed(2)}%`));
export const scientificFormat = (v, delta = false) => {
    const posPrefix = (delta && v >= 0 ? '+' : '');
    let num;
    if (v >= 1e6) {
        num = v.toPrecision(3);
    } else if (v >= 1) {
        num = v.toPrecision(6);
    } else if (v > 1e-5) {
        num = v.toFixed(6);
    } else {
        num = v.toPrecision(3);
    }
    return posPrefix + num;
};
export const tokenFormat = (v, delta = false, tokenSymbol) => scientificFormat(v, delta) + ' ' + tokenSymbol;