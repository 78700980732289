import { Avatar, Box, Grid, Tooltip, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InfoIcon from '@mui/icons-material/Info';
import { percentageFormat, scientificFormat, tokenFormat } from './number-formats';
import { farmFields, feesFields, swapTokenFields } from './metric-configuration';
import { YYIconButton } from '../components/yy-icon-button';
import { ChainIconButton } from '../components/chain-icon-button';

export const farmTableColumns = [
    {
        field: 'farmName', headerName: 'Farm', width: 250, hideable: false,
        renderCell: (params) => (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={9}>
                    <Typography variant="body2">
                        {params.value}
                    </Typography>
                </Grid>
                <Grid item xs={1.5}>
                    <YYIconButton
                        address={params.row.farmAddress}
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <ChainIconButton
                        address={params.row.farmAddress}
                    />
                </Grid>
            </Grid>
        )
    },
    ...farmFields.map(
        ({ field, name, type = 'number', width = 120, valueGetter, valueFormat, skip24HChange }) => {
            let extraObject = {};
            if (type === 'number') {
                extraObject = skip24HChange ? { valueFormatter: params => valueFormat(params.value) } : {
                    renderCell: (params) => (
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={7} container justifyContent="flex-end">
                                <Typography variant="body2">
                                    {valueFormat(params.value)}
                                </Typography>
                            </Grid>
                            <Grid item xs={5} container alignItems="center">
                                <Box sx={{ display: 'flex' }}>
                                    {params.row[field + '_change24H'] >= 0 ? <ArrowUpwardIcon color="success" fontSize='' /> : <ArrowDownwardIcon color="error" fontSize='' />}
                                    <Typography variant="caption" color={params.row[field + '_change24H'] >= 0 ? "success.main" : "error"} sx={{ mr: 1 }}>
                                        {`${Math.round(100 * Math.abs(params.row[field + '_change24H']))}%`}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )
                }
            };
            return (
                {
                    field,
                    headerName: name,
                    type,
                    width,
                    valueGetter,
                    ...extraObject
                }
            )
        }
    )
];

export const swapTableColumns = [
    {
        field: 'tokenSymbol', headerName: 'Token', width: 150, hideable: false,
        renderCell: (params) => (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="body2">
                        {params.value}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Tooltip title={(params.row.image || {}).small || "no image"}>
                        <Avatar src={(params.row.image || {}).small} sx={{ height: 25, width: 25 }}>
                            ?
                        </Avatar>
                    </Tooltip>
                </Grid>
                <Grid item xs={3}>
                    <ChainIconButton
                        address={params.row.token}
                    />
                </Grid>
            </Grid>
        )
    },
    ...swapTokenFields.map(
        ({ field, name, type = 'number', width = 140, valueGetter, valueFormat }) => {
            let extraObject = {};
            if (type === 'number') {
                extraObject = { valueFormatter: params => valueFormat(params.value) }
            };
            return (
                {
                    field,
                    headerName: name,
                    type,
                    width,
                    valueGetter,
                    ...extraObject
                }
            )
        }
    )
];

export const yakOwnershipColumns = [
    {
        field: 'holderAddressName', headerName: 'Address', width: 175, hideable: false,
        renderCell: (params) => (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={9}>
                    <Tooltip title={`${params.row.holderAddressType} - ${params.value.match(/0x/) ? '' : `${params.value} - `}${params.row.holderAddress}`}>
                        <Typography variant="body2">
                            {params.value.match(/0x/) ? `${params.value.slice(0, 10)}...` : `${params.value.length > 20 ? `${params.value.slice(0, 20)}...` : params.value}`}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={3}>
                    <ChainIconButton
                        address={params.row.holderAddress}
                        tooltipTitle={(tracker) => `View address on ${tracker}`}
                    />
                </Grid>
            </Grid>
        )
    },
    ...["1Day", "7Days", "30Days", "90Days"].map(timePeriod => (
        {
            field: `yakBalanceChange${timePeriod}`, headerName: `Balance Change (${timePeriod.split("D").join(" d")})`,
            type: 'number', width: 140, valueFormatter: (params) => scientificFormat(params.value, true)
        }
    )),
    ...[
        { field: "previousBalance", headerName: "Previous Balance", key: "before" },
        { field: "newBalance", headerName: "New Balance", key: "after" }
    ].map(
        ({ field, headerName, key }) => ({
            field, headerName, width: 120,
            renderCell: (params) => (
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={9} container justifyContent="flex-end">
                        <Typography variant="body2">
                            {scientificFormat(params.value)}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip
                            title={
                                <div>
                                    {
                                        params.row[key].length > 0 ?
                                            params.row[key].map(
                                                (d, num) => {
                                                    const label = [1, 2, 3, 4].map(i => (
                                                        [
                                                            d[`symbol${i}`] && `Deposited into ${d[`symbol${i}`]}`,
                                                            d[`stakingContractAddressName${i}`] && `Staked with ${d[`stakingContractAddressName${i}`]}`
                                                        ]
                                                    )).reduce((a, b) => a.concat(b)).filter(x => x).join(" / ") || "Spot Yak"
                                                    return <p key={num}>{label} : <span style={{ color: '#0eab4d' }}><strong>{tokenFormat(d.yakBalance, false, 'YAK')}</strong></span></p>;
                                                }
                                            )
                                            : <p>No balance</p>
                                    }
                                </div>
                            }
                        >
                            <InfoIcon color="primary" fontSize='small' />
                        </Tooltip>
                    </Grid>
                </Grid>
            )
        })
    ),
];

export const feesTableColumns = [
    {
        field: 'farmName', headerName: 'Farm', width: 250, hideable: false,
        renderCell: (params) => (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={9}>
                    <Typography variant="body2">
                        {params.value}
                    </Typography>
                </Grid>
                <Grid item xs={1.5}>
                    <YYIconButton
                        address={params.row.farmAddress}
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <ChainIconButton
                        address={params.row.farmAddress}
                    />
                </Grid>
            </Grid>
        )
    },
    ...feesFields.map(
        ({ field, name, type = 'number', width = 120, valueGetter, valueFormat }) => {
            let extraObject = {};
            if (type === 'number') {
                extraObject = { valueFormatter: params => valueFormat(params.value) }
            };
            return (
                {
                    field,
                    headerName: name,
                    type,
                    width,
                    valueGetter,
                    ...extraObject
                }
            )
        }
    ),
    {
        field: 'benefitFromYY', headerName: 'Benefit from YY', type: 'number', width: 120, valueFormatter: params => percentageFormat(params.value, true),
        renderCell: (params) => (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={9}>
                    <Typography variant="body2" color={params.value > 0 ? "success.main" : "error"} sx={{ mr: 1 }}>
                        {percentageFormat(params.value, true)}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Tooltip
                        title={
                            <div>
                                <p>The APY for the Yield Yak farm is <span style={{ color: '#0eab4d' }}><strong>{percentageFormat(params.row.APY)}</strong></span></p>
                                <p>We estimate the underlying APR (before auto-compounding and fees are taken into account) to be approximately <span style={{ color: '#0eab4d' }}><strong>{percentageFormat(params.row.APR)}</strong></span></p>
                                <p>With the input parameters above, if not using Yield Yak, the user could expect a personal APY of <span style={{ color: '#0eab4d' }}><strong>{percentageFormat(params.row.APYPersonal)}</strong></span> once gas costs and the user's personal time costs are taken into account.</p>
                                <p>The APY from Yield Yak is <span style={{ color: params.value >= 0 ? '#0eab4d' : '#d32f2f' }}><strong>{percentageFormat(params.value, true)}</strong></span> {params.value >= 0 ? 'better' : 'worse'} than you would get from the DIY approach. Therefore the recommendation on this basis would be to <span style={{ color: params.value >= 0 ? '#0eab4d' : '#d32f2f' }}><strong>{params.value >= 0 ? 'use Yield Yak' : 'DIY'}</strong></span> for this pool.</p>
                            </div>
                        }
                    >
                        <InfoIcon color={params.value > 0 ? "primary" : "error"} fontSize='small' />
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }
];