import { Box, Chip, Container, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTheme } from '@emotion/react';
import { Storage } from 'aws-amplify';
import * as React from 'react';
import { ChartCardWithModal } from '../components/chart-card-with-modal';
import { columnModels } from '../utils/column-visibility-models';
import { farmFields } from '../utils/metric-configuration';
import { useParams } from 'react-router-dom';
import { CustomDivider } from '../components/custom-divider';
import { AppDataContext } from '../utils/app-data-context';
import { YakStyledTable } from '../components/yak-styled-table';
import { farmTableColumns } from '../utils/table-columns';

export default function Farms() {
    const { chain } = React.useContext(AppDataContext);
    const [dataLatest, setDataLatest] = React.useState([]);
    const [dataHistorical, setDataHistorical] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [selectedFarms, setSelectedFarms] = React.useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(columnModels["default"]);
    const [metrics, setMetrics] = React.useState("default");
    const [selectedFarmInfo, setSelectedFarmInfo] = React.useState(false);

    let { farmAddress } = useParams();

    React.useEffect(() => {
        handleRefreshFileData();
    }, [chain]);

    React.useEffect(() => {
        handleRefreshFarmHistoricalData(dataLatest.length === 0 ? [] : [dataLatest.map(d => d.farmAddress).includes(farmAddress?.toLowerCase()) ? farmAddress?.toLowerCase() : dataLatest[0].farmAddress]);
    }, [dataLatest]);

    const handleRefreshFileData = async () => {
        setDataLatest([]);
        const latestStorageInfo = await Storage.get(`${chain}/agg-data/latestFarmData.json`, { download: true });
        let newDataLatest = await new Response(latestStorageInfo.Body).json();
        newDataLatest = newDataLatest.map(({ depositTokenInfo, depositTokenType, rewardTokenInfo, rewardTokenType, ...others }) => ({
            ...others,
            depositTokenType,
            depositTokenInfo: JSON.parse(depositTokenInfo || '{}'),
            depositTokenPricesAvailable: depositTokenType !== "unknown",
            rewardTokenType,
            rewardTokenInfo: JSON.parse(rewardTokenInfo || '{}'),
            rewardTokenPricesAvailable: rewardTokenType !== "unknown",
        }))
        setDataLatest(newDataLatest);
        setSelectionModel([newDataLatest.map(d => d.farmAddress).includes(farmAddress?.toLowerCase()) ? farmAddress?.toLowerCase() : newDataLatest[0].farmAddress]);
    };

    const handleUpdateData = () => {
        // This line is a hacky way to force the chart to re-render (otherwise the brush doesn't update).
        // We need the .concat([]), else it doesn't see a change so doesn't re-render.
        setDataHistorical(dataHistorical.concat([]));
    };

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };

    const handleColumnVisibilityModelChange = (newColumnVisibilityModel) => {
        setColumnVisibilityModel(newColumnVisibilityModel);
    };

    const handleMetricsChange = (event) => {
        setMetrics(event.target.value);
        setColumnVisibilityModel(columnModels[event.target.value]);
    };

    const handleRefreshFarmHistoricalData = async (chosenFarms) => {
        if (chosenFarms === selectedFarms) {
            // In this case, there are no changes to the chosen farms, so no point re-running.
            return;
        };
        setDataHistorical([]);
        setSelectedFarms([]);
        setSelectedFarmInfo(false);
        // We need to convert our chosenFarms into a unique address for that combination of farms, using a SHA-256 hash.
        // We do it this way (unlike in the portfolio page where there is no hash) because we allow larger numbers of farms
        // to be selected so simply concatenating the addresses together would result in file names which are too long.
        // Furthermore, we don't care about saving what has been searched for, so we don't ever need to decode the hashed
        // folder names.
        const chosenFarmsFolder = Array.prototype.map.call(
            new Uint8Array(
                await crypto.subtle.digest("SHA-256", new TextEncoder().encode([...chosenFarms].sort().join("")))
            ),
            (x) => ("0" + x.toString(16)).slice(-2)
        ).join("");
        // First thing we do is check whether a file already exists in storage for the chosen addresses.
        let matchingFiles = await Storage.list(`${chain}/daily-farm-data/farm-query-results/${chosenFarmsFolder}/`);
        matchingFiles = matchingFiles.filter(f => f.key.endsWith(".csv"));
        let farmDataPath;
        if (matchingFiles.length > 0) {
            // This means we have something which matches and therefore do not need to run the query again.
            farmDataPath = matchingFiles[0].key;
        } else {
            const response = await fetch(process.env.REACT_APP_YY_FARM_DATA_AWS_LAMBDA_URL, { method: 'POST', body: JSON.stringify({ addresses: chosenFarms, folder: chosenFarmsFolder, chain }) });
            let info = await response.json();
            farmDataPath = info.result;
        };
        // Now that we have the path for our farm data, we retrieve it from storage.
        // Sometimes the query will not have finished before we try to retrieve the data, in which case we want to wait a little before trying again.
        let keepTrying = true;
        let farmDataText;
        let numAttempts = 0;
        while (keepTrying && numAttempts < 5) {
            try {
                const farmDataStorageInfo = await Storage.get(farmDataPath, { download: true });
                farmDataText = await farmDataStorageInfo.Body.text();
                keepTrying = false;
            } catch (e) {
                console.log(e);
                console.log("Trying again...");
                const delay = (ms = 3000) => new Promise(r => setTimeout(r, ms));
                await delay();
                numAttempts += 1;
            }
        };
        // We do this double quote then single quote replacement stuff to handle the JSONs in depositTokenMetrics
        farmDataText = farmDataText.replaceAll('""', "'").replaceAll('"', '').replaceAll("'", '"');
        const lines = farmDataText.split("\n");
        const header = lines[0].split(",");
        const newDataHistorical = lines.slice(1, -1).map( // Using this slice because first value is the header, and last is an empty row.
            line => {
                const values = line.split(/(?<=[\d\}\w\,]),(?=[\d\,\{\-])/); // This fancier split takes care of depositTokenMetrics for us correctly, along with the correction below
                const newObj = {};
                header.forEach((key, i) => { newObj[key] = key === "date" ? values[i] : (key === "depositTokenMetrics" ? JSON.parse(values[i] || '{}') : Number(values[i])) });
                return newObj;
            }
        );
        // First step is to check if the combined farms have common deposit and reward tokens.
        // Also need to see if we have USD prices for those deposit and reward tokens.
        // These things will affect which charts are available to display.
        const selectedDataLatest = dataLatest.filter(({ farmAddress }) => chosenFarms.includes(farmAddress));
        if (selectedDataLatest.length > 0) {
            let { depositTokenSymbol, rewardTokenSymbol, depositTokenPricesAvailable, rewardTokenPricesAvailable } = selectedDataLatest.reduce(
                (res, row) => {
                    if (res.depositTokenAddress !== row.depositTokenAddress) {
                        res.depositTokenSymbol = false;
                    }
                    if (res.rewardTokenAddress !== row.rewardTokenAddress) {
                        res.rewardTokenSymbol = false;
                    }
                    res.depositTokenPricesAvailable = res.depositTokenPricesAvailable && row.depositTokenPricesAvailable;
                    res.rewardTokenPricesAvailable = res.rewardTokenPricesAvailable && row.rewardTokenPricesAvailable;
                    return res;
                }
            );
            setSelectedFarmInfo(({ depositTokenSymbol, rewardTokenSymbol, depositTokenPricesAvailable, rewardTokenPricesAvailable }));
        };
        setDataHistorical(newDataHistorical);
        setSelectedFarms(chosenFarms);
    }

    const theme = useTheme();

    return (
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
            <Container maxWidth={false}>
                <CustomDivider label={"FARM SNAPSHOT (LAST 1 DAY)"} />
                <YakStyledTable
                    rows={dataLatest}
                    getRowId={(row) => row.farmAddress}
                    columns={farmTableColumns}
                    checkboxSelection
                    selectionModel={selectionModel}
                    onSelectionModelChange={handleSelectionModelChange}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                    disableSelectionOnClick
                    initialState={{
                        pagination: {
                            pageSize: 10,
                        },
                        sorting: {
                            sortModel: [{ field: 'depositTokenBalanceUSD', sort: 'desc' }],
                        },
                        filter: {
                            filterModel: {
                                items: [{ columnField: 'daysSinceLastReinvest', operatorValue: '<', value: 30 }]
                            }
                        }
                    }}
                />
                <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <FormControl focused>
                            <InputLabel id="metrics-label">Metrics</InputLabel>
                            <Select
                                labelId="metrics-label"
                                id="metrics-select"
                                value={metrics}
                                label="Metrics"
                                onChange={handleMetricsChange}
                            >
                                <MenuItem value="default">Default</MenuItem>
                                <MenuItem value="balance">Balances</MenuItem>
                                <MenuItem value="depsWiths">Deposits & Withdraws</MenuItem>
                                <MenuItem value="reinvests">Reinvests</MenuItem>
                                <MenuItem value="farmInfo">Farm Info</MenuItem>
                                <MenuItem value="users">Users</MenuItem>
                            </Select>
                            <FormHelperText sx={{ color: 'text.primary' }}>Further customisation by choosing columns in the table</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={12} sm={6} md={8} xl={9}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                SELECTED FARM(S)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} sx={{ overflow: "auto", padding: 1 }}>
                                {selectionModel.map(
                                    address => {
                                        const farmName = dataLatest.filter(f => f.farmAddress === address)[0]?.farmName;
                                        return (
                                            <Tooltip key={address} title={address}>
                                                <Chip variant="outlined" color="info" label={farmName} onDelete={() => handleSelectionModelChange(selectionModel.filter(add => add !== address))} />
                                            </Tooltip>
                                        )
                                    }
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <CustomDivider customChild={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle2">
                            HISTORICAL DATA FOR SELECTED FARM(S)
                        </Typography>
                        <Tooltip title="Refresh historical data for selected farms">
                            <IconButton color="info" onClick={() => handleRefreshFarmHistoricalData(selectionModel)}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                } />
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ overflow: "auto", padding: 1 }}>
                            <Typography variant="body1">
                                Farms:
                            </Typography>
                            {selectedFarms.map(
                                address => {
                                    const farmName = dataLatest.filter(f => f.farmAddress === address)[0]?.farmName;
                                    return (
                                        <Tooltip key={address} title={address}>
                                            <Chip variant="outlined" label={farmName} />
                                        </Tooltip>
                                    )
                                }
                            )}
                        </Stack>
                        {[
                            ["Deposit token: ", selectedFarmInfo.depositTokenSymbol, "Multiple"],
                            ["Reward token: ", selectedFarmInfo.rewardTokenSymbol, "Multiple"],
                            ["Deposit token prices available: ", selectedFarmInfo.depositTokenPricesAvailable],
                            ["Reward token prices available: ", selectedFarmInfo.rewardTokenPricesAvailable],
                        ].map(
                            ([label, value, alt]) => (
                                <Typography key={label} variant="body1">
                                    <span>
                                        {label}
                                    </span>
                                    {selectedFarmInfo ?
                                        <span style={{ color: value ? 'green' : 'red' }}>
                                            {(value || alt || false).toString()}
                                        </span> : ''
                                    }
                                </Typography>
                            )
                        )}
                    </Grid>
                    {
                        farmFields.filter( // First filtering to only selected and relevant metrics
                            ({ field, type = 'number', skipChart = false }) => columnVisibilityModel[field] && type === 'number' && !skipChart
                        ).filter( // Now filter out fields which should not be included due to lack of deposit token prices/similar.
                            ({
                                hideBasedOnDepositTokenPrice = false,
                                hideBasedOnDepositTokenPriceForMultiple = false,
                                hideBasedOnDepositToken = false,
                                hideBasedOnRewardTokenPrice = false,
                                hideBasedOnRewardToken = false
                            }) => (
                                !selectedFarmInfo || (
                                    (selectedFarmInfo.depositTokenPricesAvailable || !hideBasedOnDepositTokenPrice)
                                    && (selectedFarmInfo.depositTokenPricesAvailable || !(selectedFarms.length > 1 && hideBasedOnDepositTokenPriceForMultiple))
                                    && (selectedFarmInfo.depositTokenSymbol || !hideBasedOnDepositToken)
                                    && (selectedFarmInfo.rewardTokenPricesAvailable || !hideBasedOnRewardTokenPrice)
                                    && (selectedFarmInfo.rewardTokenSymbol || !hideBasedOnRewardToken)
                                )
                            )
                        ).filter( // Now filter out columns which appear in another combo chart which is present in the above (don't need other charts for these also).
                            ({ field, mainField = field }) => !farmFields.filter(
                                ({ chartType, field, type = 'number', skipChart = false }) => chartType === 'combo' && columnVisibilityModel[field] && type === 'number' && !skipChart
                            ).map(
                                ({ yAxisBarData1, yAxisBarData2 }) => [yAxisBarData1.field, yAxisBarData2.field]
                            ).reduce((a, b) => a.concat(b), []).includes(mainField)
                        ).map(
                            ({ field, name, valueFormat, chartType = 'line', yAxisBarData1, yAxisBarData2 }) => (
                                <Grid item xs={12} md={6} key={name}>
                                    <ChartCardWithModal
                                        chartType={chartType}
                                        title={chartType === 'combo' ? `${yAxisBarData1.name} / ${yAxisBarData2.name} / ${name}` : name}
                                        data={chartType === 'combo' ? dataHistorical.map(
                                            data => {
                                                data[name] = data[field];
                                                data[yAxisBarData1.name] = data[yAxisBarData1.field];
                                                data[yAxisBarData2.name] = -data[yAxisBarData2.field];
                                                return data;
                                            }
                                        ) : dataHistorical
                                        }
                                        yAxisLineData={chartType === 'combo' ? name : field}
                                        yAxisBarData1={yAxisBarData1 && yAxisBarData1.name}
                                        yAxisBarData2={yAxisBarData2 && yAxisBarData2.name}
                                        xAxisData="dateTimestamp"
                                        valueFormat={valueFormat}
                                        handleUpdateData={handleUpdateData}
                                    />
                                </Grid>
                            )
                        )
                    }
                </Grid>
            </Container>
        </Box>
    )
};