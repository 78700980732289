import { Collapse, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@emotion/react';
import * as React from 'react';
import { currencyRounded, percentageFormat, scientificFormat, tokenFormat } from "../utils/number-formats";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export const PortfolioMetricsListItem = (props) => {
    const { displayIn, depositTokenType, metrics, symbol, icon, avatar, field, delta = false, leftPadding, variant = 'body2', startOpen = false, color, nestedFields } = props;
    const [open, setOpen] = React.useState(startOpen);

    const handleClick = () => {
        setOpen(!open);
    };

    const theme = useTheme();

    if (metrics[field] === undefined) {
        return;
    };

    let displayValue;

    if (field === "Your value") {
        displayValue = symbol ? metrics[field] && tokenFormat(metrics[field], false, symbol) : currencyRounded(metrics[field]);
    } else if (["Simple return", "TWRR"].includes(field)) {
        displayValue = percentageFormat(metrics[field], delta);
    } else {
        displayValue = symbol ? scientificFormat(metrics[field], delta) : currencyRounded(metrics[field], delta);
    };

    let displayField = field;

    if ((field === "Net deposits") && (depositTokenType === "standardLP") && (displayIn === "depositToken")) {
        displayField = (
            <Typography variant="caption" color="info.main">
                {field}
                <Tooltip title={`* ${tokenFormat(metrics[field] + metrics["Swap fees"], false, symbol)} net deposited less ${tokenFormat(metrics["Swap fees"], false, symbol)} which account for swap fees earned to date.`}>
                    <InfoIcon sx={{ fontSize: 12 }} />
                </Tooltip>
            </Typography>
        );
    }

    const ourIconOrAvatar = (
        avatar ?
            <ListItemAvatar>
                {avatar}
            </ListItemAvatar>
            : <ListItemIcon>
                {icon(theme.palette.primary.main)}
            </ListItemIcon>
    );

    const ourText = (
        <ListItemText
            primary={displayValue}
            primaryTypographyProps={{ variant: variant, color: color || (metrics[field] >= 0 ? 'primary' : 'error') }}
            secondary={displayField}
            secondaryTypographyProps={{ variant: 'caption', color: 'info.main' }}
        />
    );

    const fullItem = (
        nestedFields ?
            <div>
                <ListItemButton
                    sx={{ pl: leftPadding }}
                    disableGutters
                    onClick={handleClick}
                >
                    {ourIconOrAvatar}
                    {ourText}
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Divider color="primary" />
                <Collapse in={open}>
                    <List
                        dense
                        disablePadding
                        sx={{ bgcolor: 'background.paper' }}>
                        {
                            nestedFields.map(
                                nf => (
                                    <PortfolioMetricsListItem
                                        key={nf.field}
                                        displayIn={displayIn}
                                        depositTokenType={depositTokenType}
                                        metrics={metrics}
                                        symbol={symbol}
                                        leftPadding={leftPadding + 2}
                                        {...nf}
                                    />
                                )
                            )
                        }
                    </List>
                </Collapse>
            </div>
            : <div>
                <ListItem
                    sx={{ pl: leftPadding }}
                    disablePadding
                    disableGutters
                >
                    {ourIconOrAvatar}
                    {ourText}
                </ListItem>
            </div>
    );

    return fullItem;
};