import { Box, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { currencyRounded, integerWithCommas } from '../utils/number-formats';
import { useTheme } from '@emotion/react';
import { Storage } from 'aws-amplify';
import * as React from 'react';
import { ChartCardWithModal } from '../components/chart-card-with-modal';
import { CardCarousel } from '../components/card-carousel';
import { SingleValueCard } from '../components/single-value-card';
import { swapTokenColumnsModel } from '../utils/column-visibility-models';
import { CustomDivider } from '../components/custom-divider';
import { AppDataContext } from '../utils/app-data-context';
import { YakStyledTable } from '../components/yak-styled-table';
import { swapTableColumns } from '../utils/table-columns';

export default function YakSwap() {
    const { chain } = React.useContext(AppDataContext);
    const [dailyYakSwapData, setDailyYakSwapData] = React.useState([]);
    const [allTimeYakSwapData, setAllTimeYakSwapData] = React.useState({});
    const [dailyYakAdapterSwapData, setDailyYakAdapterSwapData] = React.useState({});
    const [dailyYakAdapterSwapDataChart, setDailyYakAdapterSwapDataChart] = React.useState([]);
    const [aggYakAdapterSwapData, setAggYakAdapterSwapData] = React.useState({});
    const [yakSwapTokenData, setYakSwapTokenData] = React.useState({});
    const [allTimeAdapterData, setAllTimeAdapterData] = React.useState({});
    const [groupAdapters, setGroupAdapters] = React.useState(true);
    const [adapterMetric, setAdapterMetric] = React.useState("tradingVolumeUSD");
    const [tokenTimePeriod, setTokenTimePeriod] = React.useState("7Days");
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(swapTokenColumnsModel);

    React.useEffect(() => {
        handleRefreshFileData();
    }, [chain]);

    const handleRefreshFileData = async () => {
        setDailyYakSwapData([]);
        setAllTimeYakSwapData({});
        setDailyYakAdapterSwapData({});
        setDailyYakAdapterSwapDataChart([]);
        setAggYakAdapterSwapData({});
        setYakSwapTokenData({});
        setAllTimeAdapterData({});
        const dailyYakSwapDataStorageInfo = await Storage.get(`${chain}/agg-yak-swap-data/dailyYakSwapData.json`, { download: true });
        const newDailyYakSwapData = await new Response(dailyYakSwapDataStorageInfo.Body).json();
        setDailyYakSwapData(newDailyYakSwapData);
        // Now we get some high-level stats for all-time.
        let newAllTimeYakSwapData = newDailyYakSwapData.reduce(
            (res, row) => {
                res.allTimeTradingVolumeUSD += row.tradingVolumeUSD;
                res.allTimeSavingsFromYakSwapUSD += row.savingsFromYakSwapUSD;
                res.allTimeNumberOfYakSwaps += row.numberOfYakSwaps;
                return res;
            },
            {
                allTimeTradingVolumeUSD: 0,
                allTimeSavingsFromYakSwapUSD: 0,
                allTimeNumberOfYakSwaps: 0
            }
        )
        newAllTimeYakSwapData.latestDate = newDailyYakSwapData[newDailyYakSwapData.length - 1].date;
        const latestDateTimestamp = newDailyYakSwapData[newDailyYakSwapData.length - 1].dateTimestamp;
        newAllTimeYakSwapData.latestDateTimestamp = latestDateTimestamp;
        setAllTimeYakSwapData(newAllTimeYakSwapData);
        // We also need to load the YakAdapter data.
        const dailyYakAdapterSwapDataStorageInfo = await Storage.get(`${chain}/agg-yak-swap-data/dailyYakAdapterSwapData.json`, { download: true });
        let newDailyYakAdapterSwapData = await new Response(dailyYakAdapterSwapDataStorageInfo.Body).json();
        newDailyYakAdapterSwapData = newDailyYakAdapterSwapData.map(
            ({ adapterGroupInfo, ...others }) => ({ ...others, adapterGroupInfo: JSON.parse(adapterGroupInfo || '{"adapterGroupName":"?Unknown?","color":"#f0f0f0","logo":"none"}') })
        );
        // First we group the daily data by adapterGroupName and then format that also for the static stuff.
        const newDailyYakAdapterSwapGroupedData = [];
        newDailyYakAdapterSwapData.reduce(
            (res, row) => {
                if (!res[row.dateTimestamp]) {
                    res[row.dateTimestamp] = {};
                };
                if (!res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName]) {
                    res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName] = {};
                    res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName].date = row.date;
                    res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName].dateTimestamp = row.dateTimestamp;
                    res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName].adapterGroupInfo = row.adapterGroupInfo;
                    res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName].adapterGroupName = row.adapterGroupInfo.adapterGroupName;
                    res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName].numberOfTrades = 0;
                    res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName].tradingVolumeUSD = 0;
                    newDailyYakAdapterSwapGroupedData.push(res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName]);
                };
                res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName].numberOfTrades += row.numberOfTrades;
                res[row.dateTimestamp][row.adapterGroupInfo.adapterGroupName].tradingVolumeUSD += row.tradingVolumeUSD;
                return res;
            }, {}
        );
        // Now we need to aggregate the daily data and get it into the right format for each date range.
        const dateRangeFilters = [
            { label: "1Day", minTimestamp: latestDateTimestamp },
            { label: "7Days", minTimestamp: latestDateTimestamp - 86400 * 6 },
            { label: "30Days", minTimestamp: latestDateTimestamp - 86400 * 29 },
            { label: "allTime", minTimestamp: 0 },
        ];
        // We use this array also to aggregate for each of the ungrouped and grouped arrays.
        const dailyDataTypes = [
            { "dataLabel": "ungrouped", dataArray: newDailyYakAdapterSwapData, idField: "adapterAddress" },
            { "dataLabel": "grouped", dataArray: newDailyYakAdapterSwapGroupedData, idField: "adapterGroupName" },
        ];
        const metrics = ["tradingVolumeUSD", "numberOfTrades"];
        const newAllTimeAdapterData = {};
        // Here we carry out the aggregation.
        const newAggYakAdapterSwapData = dateRangeFilters.reduce(
            (dateRangeResult, { label, minTimestamp }) => {
                dateRangeResult[label] = dailyDataTypes.reduce(
                    (dailyResult, { dataLabel, dataArray, idField }) => {
                        const dateRangeData = [];
                        const allTimeAdapterGroupedData = {};
                        // Here we aggregate the daily data for each date range
                        dataArray.filter(
                            ({ dateTimestamp }) => dateTimestamp >= minTimestamp
                        ).reduce(
                            (res, row) => {
                                if (!res[row[idField]]) {
                                    res[row[idField]] = {};
                                    // Doesn't matter that some of these fields are only in one array as they'll just be undefined.
                                    res[row[idField]].adapterAddress = row.adapterAddress;
                                    res[row[idField]].adapterName = row.adapterName || row.adapterAddress;
                                    res[row[idField]].adapterGroupInfo = row.adapterGroupInfo;
                                    res[row[idField]].adapterGroupName = row.adapterGroupName;
                                    res[row[idField]].numberOfTrades = 0;
                                    res[row[idField]].tradingVolumeUSD = 0;
                                    dateRangeData.push(res[row[idField]]);
                                };
                                res[row[idField]].numberOfTrades += row.numberOfTrades;
                                res[row[idField]].tradingVolumeUSD += row.tradingVolumeUSD;
                                return res;
                            }, {}
                        );
                        // Now need grouped and ungrouped adapters data for both number of trades and volume.
                        const aggData = metrics.reduce(
                            (result, metric) => {
                                const sortedData = [...dateRangeData].sort((a, b) => b[metric] - a[metric]);
                                // This extra line is to populate allTime data which we need to generate the areas/bars used in the stacked chart for the plot over time.
                                if (label === "allTime") {
                                    allTimeAdapterGroupedData[metric] = sortedData.map(
                                        ({ adapterAddress, adapterName, adapterGroupInfo, adapterGroupName, ...others }) => ({ adapterAddress, adapterName, adapterGroupInfo, adapterGroupName })
                                    );
                                };
                                const topAdapters = sortedData.slice(0, 10).map(
                                    // Again, doesn't matter that some of these fields may not be present.
                                    ({ adapterAddress, adapterName, adapterGroupInfo, adapterGroupName, ...others }) => ({ adapterAddress, adapterName, adapterGroupInfo, adapterGroupName, value: others[metric] })
                                );
                                const otherAdapters = sortedData.slice(10).reduce(
                                    (res, row) => {
                                        res.value += row[metric];
                                        return res;
                                    }, { adapterAddress: null, adapterName: "All others", adapterGroupName: "All others", adapterGroupInfo: { adapterGroupName: "Others", color: "#e84142", logo: "none" }, value: 0 }
                                );
                                topAdapters.push(otherAdapters);
                                result[metric] = topAdapters;
                                return result;
                            }, {}
                        );
                        dailyResult[dataLabel] = aggData;
                        newAllTimeAdapterData[dataLabel] = allTimeAdapterGroupedData;
                        return dailyResult;
                    }, {}
                );
                return dateRangeResult;
            }, {}
        );
        // Now we have got the aggregated data in the format we need.
        setAggYakAdapterSwapData(newAggYakAdapterSwapData);
        setAllTimeAdapterData(newAllTimeAdapterData);
        // Now we get the daily data into the right format also.
        const dailyYakData = dailyDataTypes.reduce(
            (dailyResult, { dataLabel, dataArray, idField }) => {
                dailyResult[dataLabel] = metrics.reduce(
                    (metricResult, metric) => {
                        const dailyData = [];
                        dataArray.reduce(
                            (res, row) => {
                                if (!res[row.date]) {
                                    res[row.date] = { date: row.date, dateTimestamp: row.dateTimestamp };
                                    dailyData.push(res[row.date]);
                                };
                                res[row.date][row[idField]] = row[metric];
                                return res;
                            }, {}
                        );
                        metricResult[metric] = dailyData;
                        return metricResult;
                    }, {}
                );
                return dailyResult;
            }, {}
        );
        setDailyYakAdapterSwapData(dailyYakData);
        setDailyYakAdapterSwapDataChart(dailyYakData[groupAdapters ? "grouped" : "ungrouped"][adapterMetric]);
        // Final thing we work on is the token level aggregations.
        const yakSwapTokenDataStorageInfo = await Storage.get(`${chain}/agg-yak-swap-data/yakSwapTokenData.json`, { download: true });
        const allYakSwapTokenData = await new Response(yakSwapTokenDataStorageInfo.Body).json();
        // Now we split the array of objects into smaller objects for each time period.
        // We can use the dateRangeFilters object again here.
        const newYakSwapTokenData = dateRangeFilters.reduce(
            (res, row) => {
                res[row.label] = allYakSwapTokenData.map(
                    ({ token, tokenSymbol, image, ...others }) => ({
                        token,
                        tokenSymbol,
                        image: JSON.parse(image || '{}'),
                        ...Object.keys(others).filter(
                            key => key.match(RegExp(row.label))
                        ).reduce(
                            (result, key) => {
                                result[key.split("_")[0]] = others[key];
                                return result;
                            }, {}
                        )
                    })
                ).sort(
                    (a, b) => b.yakSwapVolumeUSD - a.yakSwapVolumeUSD
                ).filter(
                    token => token.combinedSwapsFromAndToToken > 0
                );
                return res;
            }, {}
        );
        setYakSwapTokenData(newYakSwapTokenData);
    };

    const handleUpdateData = () => {
        // This line is a hacky way to force the chart to re-render (otherwise the brush doesn't update).
        // We need the .concat([]), else it doesn't see a change so doesn't re-render.
        setDailyYakSwapData([...dailyYakSwapData]);
        setDailyYakAdapterSwapDataChart([...dailyYakAdapterSwapDataChart]);
    };

    const handleChangeGroupAdapters = (event) => {
        setGroupAdapters(event.target.checked);
        setDailyYakAdapterSwapDataChart(dailyYakAdapterSwapData[event.target.checked ? "grouped" : "ungrouped"][adapterMetric]);
    };

    const handleAdapterMetricChange = (event) => {
        setAdapterMetric(event.target.value);
        setDailyYakAdapterSwapDataChart(dailyYakAdapterSwapData[groupAdapters ? "grouped" : "ungrouped"][event.target.value]);
    };

    const handleColumnVisibilityModelChange = (newColumnVisibilityModel) => {
        setColumnVisibilityModel(newColumnVisibilityModel);
    };

    const handleToggleButtonChange = (event, newTokenTimePeriod) => {
        if (!newTokenTimePeriod) {
            // do nothing in this case (clicking on the already active tokenTimePeriod makes newTokenTimePeriod null)
            return;
        }
        setTokenTimePeriod(newTokenTimePeriod);
    };

    const theme = useTheme();

    return (
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
            <Container maxWidth={false}>
                <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{ mb: 5 }}>
                    {
                        [
                            {
                                label: "Trading Volume (all time)",
                                value: allTimeYakSwapData.allTimeTradingVolumeUSD,
                                valueFormat: currencyRounded
                            },
                            {
                                label: "Yak Swap Savings (all time)",
                                value: allTimeYakSwapData.allTimeSavingsFromYakSwapUSD,
                                valueFormat: currencyRounded
                            },
                            {
                                label: "# Yak Swaps (all time)",
                                value: allTimeYakSwapData.allTimeNumberOfYakSwaps,
                                valueFormat: integerWithCommas
                            },
                        ].map(({ label, value, valueFormat }) => (
                            <Grid item xs={6} sm={4} md={3} lg={3} xl={2} key={label}>
                                <SingleValueCard
                                    label={label}
                                    value={value}
                                    valueFormat={valueFormat}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <CustomDivider label={`DATA FOR ${allTimeYakSwapData.latestDate || 'YESTERDAY'}`} />
                <CardCarousel
                    carouselItems={
                        [
                            {
                                label: "Trading Volume",
                                data: dailyYakSwapData.slice(-2).map(d => d.tradingVolumeUSD),
                                valueFormat: currencyRounded,
                                about: "The total volume of all trades executed through Yak Swap (if it's a multi-step trade then it considers the volume for each individual trade)."
                            },
                            {
                                label: "Yak Swap Volume",
                                data: dailyYakSwapData.slice(-2).map(d => d.yakSwapVolumeUSD),
                                valueFormat: currencyRounded,
                                about: "The total volume of all trades when treating each Yak Swap as a single trade."
                            },
                            {
                                label: "Unique Traders",
                                data: dailyYakSwapData.slice(-2).map(d => d.uniqueTraders),
                                valueFormat: integerWithCommas,
                                about: "The number of unique wallets who used Yak Swap in the last 1 day."
                            },
                            {
                                label: "Savings From Yak Swap",
                                data: dailyYakSwapData.slice(-2).map(d => d.savingsFromYakSwapUSD),
                                valueFormat: currencyRounded,
                                about: "In order to estimate the amount saved by using Yak Swap, we restrict our swaps to only those which are multi-step (i.e. not simple) swaps and we compare the actual amount of tokens received to the tokens that would have been received for the best available single-step swap path (if such a single step path existed at the time of the swap). If such a single step path did not exist then we don't count any savings, same for single step swaps, even though in reality Yak Swap may have saved tokens by choosing the best options in the market. For this reason, the value shown here is likely an underestimate of the amount Yak Swap actually saved."
                            },
                            {
                                label: "# Yak Swaps",
                                data: dailyYakSwapData.slice(-2).map(d => d.numberOfYakSwaps),
                                valueFormat: integerWithCommas,
                                about: "The total number of swaps using Yak Swap in the last 1 day."
                            },
                            {
                                label: "# Multi-Step Swaps",
                                data: dailyYakSwapData.slice(-2).map(d => d.numberOfMultiStepSwaps),
                                valueFormat: integerWithCommas,
                                about: "The total number of swaps using Yak Swap in the last 1 day that were at least 2 steps (i.e. not just a direct swap from the input token to the output token)."
                            },
                            {
                                label: "# Underlying Trades",
                                data: dailyYakSwapData.slice(-2).map(d => d.numberOfTrades),
                                valueFormat: integerWithCommas,
                                about: "The total number of trades made using Yak Swap once the multi-step paths are taken into account. e.g. a swap from USDC to AVAX that goes via DAI.e would be 1 swap, but 2 trades."
                            },
                            {
                                label: "# Swaps With Savings",
                                data: dailyYakSwapData.slice(-2).map(d => d.numberOfMultiStepSwapsWithSavings),
                                valueFormat: integerWithCommas,
                                about: "The total number of swaps using Yak Swap in the last 1 day where Yak Swap has saved the trader tokens compared to the best alternative single-step path."
                            }
                        ]
                    }
                />
                <CustomDivider label={"ADAPTER DATA"} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-evenly">
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={groupAdapters}
                                        onChange={handleChangeGroupAdapters}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } label="Group adapters together" />
                            </FormGroup>
                            <FormControl focused>
                                <FormLabel id="radio-buttons-adapter-metric-label">Metric</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="radio-buttons-adapter-metric-label"
                                    name="radio-buttons-adapter-metric-group"
                                    value={adapterMetric}
                                    onChange={handleAdapterMetricChange}
                                >
                                    <FormControlLabel value="tradingVolumeUSD" control={<Radio />} label="Volume" />
                                    <FormControlLabel value="numberOfTrades" control={<Radio />} label="# Trades" />
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ChartCardWithModal
                            chartType="horizontal bar"
                            title="Top Adapters"
                            data={aggYakAdapterSwapData}
                            groupAdapters={groupAdapters ? "grouped" : "ungrouped"}
                            adapterMetric={adapterMetric}
                            initialLookBack={"7Days"}
                            yAxisBarData1={groupAdapters ? "adapterGroupName" : "adapterName"}
                            xAxisData="value"
                            valueFormat={adapterMetric === "numberOfTrades" ? integerWithCommas : currencyRounded}
                            handleUpdateData={handleUpdateData}
                            chartHeight={300}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ChartCardWithModal
                            chartType="stacked area"
                            title="Adapters Over Time"
                            data={dailyYakAdapterSwapDataChart}
                            extraData={allTimeAdapterData[groupAdapters ? "grouped" : "ungrouped"] ? allTimeAdapterData[groupAdapters ? "grouped" : "ungrouped"][adapterMetric] : []}
                            groupAdapters={groupAdapters ? "grouped" : "ungrouped"}
                            adapterMetric={adapterMetric}
                            yAxisLineData={groupAdapters ? "adapterGroupName" : "adapterName"}
                            xAxisData="dateTimestamp"
                            valueFormat={adapterMetric === "numberOfTrades" ? integerWithCommas : currencyRounded}
                            handleUpdateData={handleUpdateData}
                            chartHeight={300}
                        />
                    </Grid>
                </Grid>
                <CustomDivider label="TOKEN DATA" sxProps={{ mb: 3, mt: 3 }} />
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                    <Grid item xs={12} justifyContent="center">
                        <ToggleButtonGroup value={tokenTimePeriod} size="small" exclusive onChange={handleToggleButtonChange} sx={{ backgroundColor: theme.palette.secondary.main, background: "linear-gradient(#553f77, #303030)" }}>
                            <ToggleButton value="1Day">1 day</ToggleButton>
                            <ToggleButton value="7Days">7 days</ToggleButton>
                            <ToggleButton value="30Days">30 days</ToggleButton>
                            <ToggleButton value="allTime">All time</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <YakStyledTable
                            rows={yakSwapTokenData[tokenTimePeriod] || []}
                            getRowId={(row) => row.token}
                            columns={swapTableColumns}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                        />
                    </Grid>
                </Grid>
                <CustomDivider label="HISTORICAL DATA" sxProps={{ mb: 3, mt: 3 }} />
                <Grid container spacing={3}>
                    {
                        [
                            { title: "Trading Volume", yAxisLineData: "tradingVolumeUSD", valueFormat: currencyRounded },
                            { title: "Yak Swap Volume", yAxisLineData: "yakSwapVolumeUSD", valueFormat: currencyRounded },
                            { title: "Unique Traders", yAxisLineData: "uniqueTraders" },
                            { title: "Savings From Yak Swap", yAxisLineData: "savingsFromYakSwapUSD", valueFormat: currencyRounded },
                            { title: "# Yak Swaps", yAxisLineData: "numberOfYakSwaps" },
                            { title: "# Multi-Step Swaps", yAxisLineData: "numberOfMultiStepSwaps" },
                            { title: "# Underlying Trades", yAxisLineData: "numberOfTrades" },
                            { title: "# Swaps With Savings", yAxisLineData: "numberOfMultiStepSwapsWithSavings" },
                        ].map(
                            ({ title, yAxisLineData, valueFormat = integerWithCommas }) => (
                                <Grid item xs={12} md={6} key={title}>
                                    <ChartCardWithModal
                                        chartType="line"
                                        title={title}
                                        data={dailyYakSwapData}
                                        yAxisLineData={yAxisLineData}
                                        xAxisData="dateTimestamp"
                                        valueFormat={valueFormat}
                                        handleUpdateData={handleUpdateData}
                                    />
                                </Grid>
                            )
                        )
                    }
                </Grid>
            </Container>
        </Box>
    )
};