import * as React from 'react';
import * as d3 from 'd3';
import { tokenFormat } from '../utils/number-formats';
import { useTheme } from '@emotion/react';

export const SunburstChart = ({ data, tracker, chain, groupEOAContractPositions }) => {
    const svgRef = React.useRef();

    const theme = useTheme();

    const width = 600;

    const threshold = 10;

    const createHierarchyWithOthers = (node) => {
        if (!node.children) return node;
        let total = 0;
        let children = node.children.filter(child => child.value >= threshold);
        let numberOtherChildren = node.children.filter(child => child.value < threshold).length;
        node.children = children.map(child => {
            const newChild = groupEOAContractPositions ? child : createHierarchyWithOthers(child);
            total += child.value;
            return newChild;
        });
        if (total < node.value) {
            const otherNode = {
                data: {
                    name: "Others",
                    label: "Others",
                    title: `<a>Others (< ${threshold} Yak balance)</a><br><p>${node.ancestors().map(d => `<span>${tokenFormat(d.value, false, 'YAK')}</span> ${d.data.title}`).reverse().join("<br>of which ")}<br> of which <span>${tokenFormat(node.value - total, false, 'YAK')}</span> in ${numberOtherChildren} addresses with < ${threshold} Yak balance</p>`
                },
                value: node.value - total
            };
            node.children.push(otherNode);
        }
        return node;
    };

    const partition = data => {
        const root = d3.hierarchy(data)
            .sum(d => d.yakBalance)
            .sort((a, b) => b.yakBalance - a.yakBalance);
        createHierarchyWithOthers(root);
        return d3.partition()
            .size([2 * Math.PI, root.height + 1])
            (root);
    };

    // const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, 10));
    const color = d3.scaleOrdinal([theme.palette.primary.main, theme.palette.secondary.main, theme.palette.info.main, theme.palette.avax.main]);

    // const color = d3.scaleLinear()
    //     .domain([0, 8000])
    //     .range([theme.palette.text.primary, theme.palette.primary.main]);

    const tooltipColor = d3.color(theme.palette.background.default);
    tooltipColor.opacity = 0.75;

    const format = d3.format(",d");

    const radius = width / 8;

    const arc = d3.arc()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
        .padRadius(radius * 1.5)
        .innerRadius(d => d.y0 * radius)
        .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1));

    React.useEffect(() => {
        const root = partition(data);

        root.each(d => d.current = d);

        const svg = d3.select(svgRef.current)
            .attr("viewBox", [0, 0, width, width])
            .style("font", "10px roboto");

        const g = svg.append("g")
            .attr("transform", `translate(${width / 2}, ${width / 2})`);

        const path = g.append("g")
            .selectAll("path")
            .data(root.descendants().slice(1))
            .join("path")
            .attr("fill", d => { if (d.data.name === "Others") return theme.palette.text.disabled; while (d.depth > 1) d = d.parent; return color(d.data.name); })
            // .attr("fill", d => color(d.value))
            .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
            .attr("pointer-events", d => arcVisible(d.current) ? "auto" : "none")
            .attr("d", d => arc(d.current));

        const tooltip = d3.select("body")
            .append("div")
            .style("position", "absolute")
            .style("pointer-events", "auto")
            .style("padding", "10px")
            .style("background-color", tooltipColor.formatRgb())
            .style("border", `1px solid ${theme.palette.text.primary}`)
            .style("box-shadow", "1px 1px 3px rgba(0, 0, 0, 0.3)")
            .style("border-radius", "3px")
            .style("font-size", "14px");

        tooltip.on("mouseover", () => {
            tooltip.style("opacity", 1)
                .style("display", "block");
        })
            .on("mouseout", () => {
                tooltip.style("opacity", 0)
                    .style("display", "none");
            });

        path.on("mouseover", (event, d) => {
            tooltip.html(d.data.name === "Others" ? d.data.title : `<a href="https://${tracker}.io/address/${d.data.name}" target="_blank" rel="noreferrer">${d.data.label}</a><br><p>${d.ancestors().map(d => `<span>${tokenFormat(d.value, false, 'YAK')}</span> ${d.data.title}`).reverse().join("<br>of which ")}</p>`)
                .transition()
                .delay('500')
                .duration('50')
                .style("opacity", 1)
                .style("display", "block");
            tooltip.style("left", (event.pageX) + "px")
                .style("top", (event.pageY) + "px");
            tooltip.selectAll("a")
                .style("color", theme.palette[chain].main)
            tooltip.selectAll("span")
                .style("color", theme.palette.primary.main)
            if (d.children) {
                d3.select(event.target).attr("fill-opacity", 0.5)
            };
        })
            .on("mouseout", (event, d) => {
                if (event.relatedTarget === tooltip.node()) {
                    return;
                };
                tooltip.transition()
                    .duration('50')
                    .style("opacity", 0)
                    .style("display", "none");
                if (d.children) {
                    d3.select(event.target).attr("fill-opacity", 0.6)
                }
            });

        path.filter(d => d.children)
            .style("cursor", "pointer")
            .on("click", clicked);

        const label = g.append("g")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none")
            .selectAll("text")
            .data(root.descendants().slice(1))
            .join("text")
            .attr("dy", "0.35em")
            .attr("fill", "#fff")
            // .attr("font-size", "20px")
            .attr("fill-opacity", d => +labelVisible(d.current))
            .attr("transform", d => labelTransform(d.current))
            .text(d => `${d.data.label.slice(0, 10)}${d.data.label.length > 10 ? '...' : ''}`);

        const parent = g.append("circle")
            .datum(root)
            .attr("r", radius)
            .attr("fill", "none")
            .attr("pointer-events", "all")
            .style("cursor", "pointer")
            .on("click", clicked)
            .on("mouseover", (event, d) => { d3.select(event.target).attr("fill", "#000") })
            .on("mouseout", (event, d) => { d3.select(event.target).attr("fill", "none") });

        const parentLabel = g.append("text")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none")
            .selectAll("tspan")
            .data([root.data.name, tokenFormat(root.value, false, 'YAK')])
            .join("tspan")
            .attr("x", 0)
            .attr("y", (d, i, nodes) => `${2 * i - nodes.length / 2 + 0.8}em`)
            .attr("fill", "#fff")
            .text(d => d)
            .attr("id", (d, i) => `parentLabel-tspan-${i}`);

        function clicked(event, p) {
            parent.datum(p.parent || root);
            d3.select("#parentLabel-tspan-0")
                .text(p.data.label.length > 20 ? `${p.data.label.slice(0, 20)}...` : p.data.label);
            d3.select("#parentLabel-tspan-1")
                .text(tokenFormat(p.value, false, 'YAK'));

            root.descendants().forEach(d => d.target = {
                x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                y0: Math.max(0, d.y0 - p.depth),
                y1: Math.max(0, d.y1 - p.depth)
            });

            const t = g.transition().duration(750);

            // Transition the data on all arcs, even the ones that aren’t visible,
            // so that if this transition is interrupted, entering arcs will start
            // the next transition from the desired position.
            path.transition(t)
                .tween("data", d => {
                    const i = d3.interpolate(d.current, d.target);
                    return t => d.current = i(t);
                })
                .filter(function (d) {
                    return +this.getAttribute("fill-opacity") || arcVisible(d.target);
                })
                .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
                .attr("pointer-events", d => arcVisible(d.target) ? "auto" : "none")

                .attrTween("d", d => () => arc(d.current));

            label.filter(function (d) {
                return +this.getAttribute("fill-opacity") || labelVisible(d.target);
            }).transition(t)
                .attr("fill-opacity", d => +labelVisible(d.target))
                .attrTween("transform", d => () => labelTransform(d.current));
        }

        function arcVisible(d) {
            return d.y1 <= 4 && d.y0 >= 1 && d.x1 > d.x0;
        }

        function labelVisible(d) {
            return d.y1 <= 4 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.04;
        }

        function labelTransform(d) {
            const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
            const y = (d.y0 + d.y1) / 2 * radius;
            return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
        }

        return () => {
            svg.selectAll('*').remove();
        };
    }, [data])

    return (
        <svg ref={svgRef} width="min(75vh, 80vw)" height="min(75vh, 80vw)">

        </svg>
    );
};