import * as React from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Outlet, useSearchParams } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { theme } from './theme';
import { DashboardLayout } from './components/dashboard-layout';
import { AppDataContext } from './utils/app-data-context';

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [chain, setChain] = React.useState(["avalanche", "arbitrum"].includes(searchParams.get("chain")) ? searchParams.get("chain") : "avalanche");

  const updateChain = (newChain) => {
    setChain(newChain);
  };

  const value = { chain, updateChain };

  return (
    // <div className="App">
    //   <header className="App-header">
    <AppDataContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      </ThemeProvider>
    </AppDataContext.Provider>
    //   {/* </header>
    //   {/* <Authenticator>
    //     {({ signOut, user }) => (
    //       <main>
    //         <h1>Hello {user.username}</h1>
    //         <button onClick={signOut}>Sign out</button>
    //       </main>
    //     )}
    //   </Authenticator> */}
    // </div> */}
  );
}

export default App;
