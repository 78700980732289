import { Card, CardContent, Grid, Typography } from "@mui/material";

export const SingleValueCard = (props) => {
    const { label, value, valueFormat } = props;
    return (
        <Card sx={{ height: '100%', background: "linear-gradient(#1e2220, #121614)", border: 1, borderColor: 'rgb(255,255,255)'  }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                    <Grid item xs={12}>
                        <Typography variant="h3" color="primary" sx={{ fontWeight: 400, fontSize: { xs: 40, sm: 44, md: 48 } }}>
                            {value ? valueFormat(value) : '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            {label}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}