import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Home from './pages/home';
import Farms from './pages/farms';
import Users from './pages/users';
import Portfolio from './pages/portfolio';
import YakSwap from './pages/yakswap';
import YakToken from './pages/yaktoken';
import Fees from './pages/fees';
import Treasury from './pages/treasury';
import NotFound from './pages/notfound';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Amplify from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="farms" element={<Farms />} />
          <Route path="farms/:farmAddress" element={<Farms />} />
          <Route path="users" element={<Users />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="yakswap" element={<YakSwap />} />
          <Route path="yaktoken" element={<YakToken />} />
          <Route path="fees" element={<Fees />} />
          {/* <Route path="treasury" element={<Treasury />} /> */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
