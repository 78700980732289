import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export const YakStyledTable = (props) => {
    return (
        <DataGrid
            density='compact'
            headerHeight={80}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            initialState={{
                pagination: {
                    pageSize: 10,
                },
            }}
            sx={{
                boxShadow: 2,
                border: 0,
                mb: 3,
                color: 'text.primary',
                backgroundColor: 'background.paper',
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'primary.main',
                    background: 'linear-gradient(to right, #0eab4d, #121614)',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                    padding: 1
                },
                '& .MuiTablePagination-root': {
                    color: 'text.primary',
                },
                '& .MuiSelect-icon': {
                    color: 'text.primary',
                },
                '& .MuiButtonBase-root': {
                    color: 'text.primary',
                },
                '& .MuiDataGrid-booleanCell path': {
                    color: 'text.primary',
                },
                '& .MuiMenuItem-root': {
                    color: 'background.default',
                },
                '& .MuiCheckbox-root svg': {
                    color: 'info.main',
                    borderColor: 'info.main',
                },
            }}
            {...props}
        />
    )
}