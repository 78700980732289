import { Box, Container, Grid } from '@mui/material';
import { integerWithCommas, percentageFormat, scientificFormat } from '../utils/number-formats';
import { useTheme } from '@emotion/react';
import { Storage } from 'aws-amplify';
import * as React from 'react';
import { ChartCardWithModal } from '../components/chart-card-with-modal';
import { CardCarousel } from '../components/card-carousel';
import { CustomDivider } from '../components/custom-divider';
import { AppDataContext } from '../utils/app-data-context';

export default function Users() {
    const { chain } = React.useContext(AppDataContext);
    const [dailyUserData, setDailyUserData] = React.useState([]);
    const [extraUserData, setExtraUserData] = React.useState({});

    React.useEffect(() => {
        handleRefreshFileData();
    }, [chain]);

    const handleRefreshFileData = async () => {
        setDailyUserData([]);
        setExtraUserData([]);
        const dailyUserDataStorageInfo = await Storage.get(`${chain}/agg-data/dailyData.json`, { download: true })
        const newDailyUserData = await new Response(dailyUserDataStorageInfo.Body).json()
        setDailyUserData(newDailyUserData);
        const newExtraUserData = { latestDate: newDailyUserData[newDailyUserData.length - 1].date };
        setExtraUserData(newExtraUserData);
    };

    const handleUpdateData = () => {
        // This line is a hacky way to force the chart to re-render (otherwise the brush doesn't update).
        // We need the .concat([]), else it doesn't see a change so doesn't re-render.
        setDailyUserData(dailyUserData.concat([]));
    };

    const theme = useTheme();

    return (
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
            <Container maxWidth={false}>
                <CustomDivider label={`DATA FOR ${extraUserData.latestDate || 'YESTERDAY'}`} />
                <CardCarousel
                    carouselItems={
                        [
                            {
                                label: "Active wallets",
                                data: dailyUserData.slice(-2).map(d => d.numberofactivewallets),
                                valueFormat: integerWithCommas,
                                about: "The total number of wallets which are active on the site (i.e. have a combined farm balance of more than $1)."
                            },
                            {
                                label: "$1K wallets",
                                data: dailyUserData.slice(-2).map(d => d.numberof1kwallets),
                                valueFormat: integerWithCommas,
                                about: "The total number of wallets which have a combined farm balance of more than $1K."
                            },
                            {
                                label: "Whale wallets",
                                data: dailyUserData.slice(-2).map(d => d.numberofwhalewallets),
                                valueFormat: integerWithCommas,
                                about: "The total number of wallets which have a combined farm balance of more than $1M."
                            },
                            {
                                label: "Depositing wallets",
                                data: dailyUserData.slice(-2).map(d => d.numberofdepositingwallets),
                                valueFormat: integerWithCommas,
                                about: "The total number of wallets which made at least one deposit in the last 1 day."
                            },
                            {
                                label: "New wallets",
                                data: dailyUserData.slice(-2).map(d => d.numberofnewwallets),
                                valueFormat: integerWithCommas,
                                about: "The total number of wallets that had any kind of activity on the platform for the first time yesterday."
                            },
                            {
                                label: "New active wallets",
                                data: dailyUserData.slice(-2).map(d => d.numberofnewactivewallets),
                                valueFormat: integerWithCommas,
                                about: "The total number of wallets that had a combined farm balance greater than $1 for the first time yesterday."
                            },
                            {
                                label: "Farms per active wallet",
                                data: dailyUserData.slice(-2).map(d => d.avgfarmsperactivewallet),
                                valueFormat: scientificFormat,
                                about: "The average number of farms in which an active wallet has a farm balance greater than $1."
                            },
                            {
                                label: "Churn rate",
                                data: dailyUserData.slice(-2).map(d => d.churnrate),
                                valueFormat: percentageFormat,
                                about: "The number of wallets going from a combined farm balance greater than $1 to a combined farm balance less than or equal to $1, as a percentage of the number of wallets previously having a combined farm balance greater than $1."
                            },
                            {
                                label: "Reactivation rate",
                                data: dailyUserData.slice(-2).map(d => d.reactivationrate),
                                valueFormat: percentageFormat,
                                about: "The number of wallets going from a combined farm balance less than or equal to $1 to a combined farm balance greater than $1, as a percentage of the number of wallets previously having a combined farm balance less than or equal to $1, and where this is not the first day they have recorded a combined farm balance greater than $1."
                            },
                            {
                                label: "% TVL from whales",
                                data: dailyUserData.slice(-2).map(d => d.percentagetvlfromwhales),
                                valueFormat: percentageFormat,
                                about: "The combined farm balances of wallets with individual whale balances (> $1M) as a percentage of total TVL."
                            }
                        ]
                    }
                />
                <CustomDivider label={"HISTORICAL DATA"} />
                <Grid container spacing={3}>
                    {
                        [
                            { title: "Active wallets", yAxisLineData: "numberofactivewallets" },
                            { title: "$1K wallets", yAxisLineData: "numberof1kwallets" },
                            { title: "Whale wallets", yAxisLineData: "numberofwhalewallets" },
                            { title: "Depositing wallets", yAxisLineData: "numberofdepositingwallets" },
                            { title: "New wallets", yAxisLineData: "numberofnewwallets" },
                            { title: "New active wallets", yAxisLineData: "numberofnewactivewallets" },
                            { title: "Farms per active wallet", yAxisLineData: "avgfarmsperactivewallet", valueFormat: scientificFormat },
                            { title: "Churn rate", yAxisLineData: "churnrate", valueFormat: percentageFormat },
                            { title: "Reactivation rate", yAxisLineData: "reactivationrate", valueFormat: percentageFormat },
                            { title: "% TVL from whales", yAxisLineData: "percentagetvlfromwhales", valueFormat: percentageFormat },
                        ].map(
                            ({ title, yAxisLineData, valueFormat = integerWithCommas }) => (
                                <Grid item xs={12} md={6} key={title}>
                                    <ChartCardWithModal
                                        chartType="line"
                                        title={title}
                                        data={dailyUserData}
                                        yAxisLineData={yAxisLineData}
                                        xAxisData="datetimestamp"
                                        valueFormat={valueFormat}
                                        handleUpdateData={handleUpdateData}
                                    />
                                </Grid>
                            )
                        )
                    }
                </Grid>
            </Container>
        </Box>
    )
};