import { Box, Button, Card, CardContent, Grid, MobileStepper, Slide, Tooltip, Typography } from "@mui/material";
import { SummaryInfoCard } from '../components/summary-info-card';
import { useTheme } from '@emotion/react';
import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

export const CardCarousel = (props) => {
    const { carouselItems } = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [moveInLeft, setMoveInLeft] = React.useState(true);

    const theme = useTheme();

    const upXL = useMediaQuery(theme.breakpoints.up("xl"));
    const upMD = useMediaQuery(theme.breakpoints.up("md"));
    const upSM = useMediaQuery(theme.breakpoints.up("sm"));
    const itemsInCarousel = upXL ? 6 : (upMD ? 4 : (upSM ? 3 : 2));

    const maxSteps = Math.ceil(carouselItems.length / itemsInCarousel);

    let adjustedCarouselItems = [];
    for (let i = 0; i < carouselItems.length; i += itemsInCarousel) {
        const chunk = carouselItems.slice(i, i + itemsInCarousel);
        adjustedCarouselItems.push(chunk);
    };

    const containerRef = React.useRef(null);

    const handleNext = () => {
        setMoveInLeft(true);
        setActiveStep((prevActiveStep) => Math.min(maxSteps - 1, prevActiveStep) + 1);
    };

    const handleBack = () => {
        setMoveInLeft(false);
        setActiveStep((prevActiveStep) => Math.min(maxSteps - 1, prevActiveStep) - 1);
    };

    return (
        <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item xs={12}
                sx={{
                    height: 210,
                    position: 'relative',
                    overflow: 'hidden'
                }}
                ref={containerRef}
            >
                {
                    adjustedCarouselItems.map(
                        (pageItems, num) => (
                            <Slide direction={num === Math.min(maxSteps - 1, activeStep) ? (moveInLeft ? "left" : "right") : (moveInLeft ? "right" : "left")} in={num === Math.min(maxSteps - 1, activeStep)} container={containerRef.current} key={num} sx={{ position: 'absolute', top: 0, width: '100%' }}>
                                <Grid container spacing={3} sx={{ mb: 5 }}>
                                    {
                                        pageItems.map(({ label, data, valueFormat, about }) => (
                                            <Grid item xs={6} sm={4} md={3} lg={3} xl={2} key={label}>
                                                <SummaryInfoCard
                                                    label={label}
                                                    data={data}
                                                    valueFormat={valueFormat}
                                                    about={about}
                                                />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Slide>
                        )
                    )
                }
            </Grid>
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={maxSteps}
                    position="static"
                    activeStep={Math.min(maxSteps - 1, activeStep)}
                    sx={{ flexGrow: 1 }}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            </Grid>
        </Grid>
    )
}