import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from "@emotion/react";

import { SidebarItem } from './sidebar-item'
import yieldYakLogo from '../assets/logos/yieldyak-logo.png';
import coinGeckoLogo from '../assets/logos/coingecko_logo_without_text.png';
import theGraphLogo from '../assets/logos/the-graph.png';
// import covalentLogo from '../assets/logos/covalent-logomark-white.png';

export const sidebarItems = [
    {
        href: "/",
        title: "Home",
        chains: ["avalanche", "arbitrum"],
    },
    {
        href: "/farms",
        title: "Farms",
        chains: ["avalanche", "arbitrum"],
    },
    {
        href: "/users",
        title: "Users",
        chains: ["avalanche", "arbitrum"],
    },
    {
        href: "/portfolio",
        title: "Portfolio tracker",
        chains: ["avalanche", "arbitrum"],
    },
    {
        href: "/yakswap",
        title: "Yak Swap",
        chains: ["avalanche", "arbitrum"],
    },
    {
        href: "/yaktoken",
        title: "Yak Token",
        chains: ["avalanche", "arbitrum"],
    },
    {
        href: "/fees",
        title: "Fees",
        chains: ["avalanche", "arbitrum"],
    },
    //{
    //    href: "/treasury",
    //    title: "Treasury",
    //},
];

export const DashboardSidebar = (props) => {
    const { open, onClose } = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const theme = useTheme();

    const content = (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', background: "linear-gradient(to left, #1e2220, #121614)" }}>
                <Grid container alignItems="center" justifyContent="center" sx={{ pt: 5 }}>
                    <a href="https://yieldyak.com/" target="_blank" rel="noreferrer">
                        <img alt="Yield Yak" src={yieldYakLogo} height="auto" width={150} />
                    </a>
                </Grid>
                <Divider sx={{ borderColor: theme.palette.primary.main, my: 3 }} />
                <Box sx={{ flexGrow: 1, my: 3 }}>
                    {
                        sidebarItems.map((item) => (
                            <SidebarItem
                                key={item.title}
                                href={item.href}
                                title={item.title}
                                onClose={onClose}
                            />)
                        )
                    }
                </Box>
                <Box sx={{ px: 2, py: 3 }}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center" sx={{ pt: 5 }}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                Powered by
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {[
                                { href: "https://www.coingecko.com/", alt: "CoinGecko", logo: coinGeckoLogo },
                                { href: "https://thegraph.com/", alt: "The Graph", logo: theGraphLogo },
                                // { href: "https://www.covalenthq.com/", alt: "Covalent", logo: covalentLogo },
                            ].map(({ href, alt, logo }) => {
                                return (
                                    <a key={alt} href={href} target="_blank" rel="noreferrer" style={{ padding: 2 }}>
                                        <img alt={alt} src={logo} height={40} width="auto" />
                                    </a>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{ sx: { width: 280 } }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{ sx: { width: 280 } }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};