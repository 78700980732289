import { Box, Container, Grid, Typography } from '@mui/material';

export default function NotFound() {
    return (
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
            <Container maxWidth={false}>
                <Grid container spacing={3} sx={{ mb: 5 }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            404 - Cannot find the page you are looking for. Try using the navigation menu.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
};