import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ListItem } from '@mui/material';

export const SidebarItem = (props) => {
    const { href, title, onClose } = props;
    let location = useLocation();
    const active = href ? (location.pathname === href) : false;

    return (
        <ListItem
            button
            component={RouterLink}
            to={href}
            onClick={() => onClose()}
            sx={{
                backgroundColor: active && 'rgba(255,255,255, 0.08)',
                color: active ? 'primary.main' : 'text.primary',
                fontWeight: active && 'fontWeightBold',
                '&:hover': {
                    backgroundColor: 'rgba(255,255,255, 0.08)'
                }
            }}>
            {title}
        </ListItem>
    )
}