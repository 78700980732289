import { Card, CardContent, Grid, IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import * as React from 'react';
import { useTheme } from "@emotion/react";

export const ChartCard = (props) => {
    const { title, chartType, lookBack, handleToggleButtonChange, handleModalOpen, componentRef, isFullScreen, isMiniChart, children } = props;
    const theme = useTheme();

    return (
        <Card sx={{ height: '100%' }}>
            <CardContent>
                <Grid container spacing={isMiniChart ? 0 : 1} alignItems="center" justifyContent="flex-start">
                    <Grid item xs={isMiniChart ? 10 : 4} sm={6}>
                        <Typography variant={isMiniChart ? "caption" : "h6"} color="primary" sx={{ fontWeight: isMiniChart ? 400 : 500, fontSize: isMiniChart ? { xs: 12 } : { xs: 16, sm: 18, md: 20 } }}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid container item xs={isMiniChart ? 2 : 8} sm={6} alignItems="center" justifyContent="flex-end">
                        <Grid item hidden={isMiniChart}>
                            <ToggleButtonGroup value={lookBack} size="small" exclusive onChange={handleToggleButtonChange} sx={{ backgroundColor: theme.palette.secondary.main, background: "linear-gradient(#553f77, #303030)" }}>
                                {chartType !== 'horizontal bar' ? '' : <ToggleButton value={"1Day"}>1 day</ToggleButton>}
                                <ToggleButton value={chartType !== 'horizontal bar' ? 7 : "7Days"}>7 days</ToggleButton>
                                <ToggleButton value={chartType !== 'horizontal bar' ? 30 : "30Days"}>30 days</ToggleButton>
                                <ToggleButton value={chartType !== 'horizontal bar' ? -1 : "allTime"}>All time</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item sx={{ display: isMiniChart ? 'inline' : { xs: 'none', sm: 'inline' } }}>
                            <IconButton onClick={handleModalOpen}>
                                {isFullScreen ? <CloseFullscreenIcon sx={{ color: theme.palette.text.primary }} fontSize={isMiniChart ? "small" : "small"} />
                                    : <OpenInFullIcon sx={{ color: theme.palette.text.primary }} fontSize={isMiniChart ? "small" : "small"} />}
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} ref={componentRef} sx={{ '& .recharts-brush': { fill: 'green', fontSize: '0.8rem' } }}>
                        {children}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}