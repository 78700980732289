import { Box, Modal, Skeleton } from "@mui/material";
import * as React from 'react';
import { ChartCard } from "./chart-card";
import { GenericChart } from "./generic-chart";

// This function is used in order to make recharts charts responsive, by detecting the size of
// the component in which they find themselves. Using this because recharts own ResponsiveContainer
// does not work properly for page resizes, only works when the page is refreshed.
const useResize = (myRef) => {
    const [width, setWidth] = React.useState(0);

    React.useEffect(() => {
        const handleResize = () => {
            setWidth(myRef.current.offsetWidth)
        }

        if (myRef.current) {
            setWidth(myRef.current.offsetWidth);
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [myRef])

    return { width }
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%"
};

export const ChartCardWithModal = (props) => {
    const { chartType, title, data, handleUpdateData, chartHeight = 200, initialLookBack = 7, isMiniChart = false, groupAdapters, adapterMetric } = props;
    // Rather than using data.length here, we go with a large value (9999), as otherwise, while the data length is 0, it puts that as the inital state and doesn't update when data loads.
    const [brushIndex, setBrushIndex] = React.useState({ startIndex: initialLookBack === -1 ? 9999 : initialLookBack, endIndex: 0 });
    const [lookBack, setLookBack] = React.useState(initialLookBack);
    const [animationActive, setAnimationActive] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const componentRef = React.useRef();
    const { width } = useResize(componentRef);
    const chartData = chartType !== 'horizontal bar' ? data : (data[lookBack] ? data[lookBack][groupAdapters][adapterMetric] : []);

    const handleModalOpen = () => {
        setModalOpen(!modalOpen);
    }

    const handleBrushIndexChange = ({ startIndex, endIndex }) => {
        setBrushIndex({ startIndex: chartData.length - startIndex, endIndex: chartData.length - 1 - endIndex });
        setLookBack(null);
    };

    const handleToggleButtonChange = (event, newLookBack) => {
        if (!newLookBack) {
            // do nothing in this case (clicking on the already active lookBack makes newLookBack null)
            return;
        }
        // setAnimationActive(true); // Can enable this line if we want to add some animation - done this way for the sake of the initial render.
        setLookBack(newLookBack);
        // Here we adjust the index value for the brush component also which will change our display as desired.
        const newStartIndex = newLookBack === -1 ? chartData.length : newLookBack;
        setBrushIndex({ startIndex: newStartIndex, endIndex: 0 });
        // This line is a hacky way to force the chart to re-render (otherwise the brush doesn't update).
        handleUpdateData();
    };

    return (
        <>
            <ChartCard
                title={title}
                chartType={chartType}
                lookBack={lookBack}
                handleToggleButtonChange={handleToggleButtonChange}
                handleModalOpen={handleModalOpen}
                componentRef={componentRef}
                isMiniChart={isMiniChart}
            >
                {chartData.length === 0 ? <Skeleton variant="rectangular" width={0.99 * width} height={chartHeight} /> :
                    <GenericChart
                        chartWidth={0.99 * width}
                        chartHeight={chartHeight}
                        fontSize='0.8rem'
                        chartData={chartData}
                        otherChartProps={props}
                        animationActive={animationActive}
                        brushIndex={brushIndex}
                        handleBrushIndexChange={handleBrushIndexChange}
                        isMiniChart={isMiniChart}
                    />
                }
            </ChartCard>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Box sx={style}>
                    <ChartCard
                        title={title}
                        chartType={chartType}
                        lookBack={lookBack}
                        handleToggleButtonChange={handleToggleButtonChange}
                        handleModalOpen={handleModalOpen}
                        componentRef={undefined}
                        isFullScreen
                    >
                        {chartData === [] ? <Skeleton variant="rectangular" width={0.99 * width} height={200} /> :
                            <GenericChart
                                chartWidth={0.75 * window.innerWidth}
                                chartHeight={0.6 * window.innerHeight}
                                fontSize='1rem'
                                chartData={chartData}
                                otherChartProps={props}
                                animationActive={animationActive}
                                brushIndex={brushIndex}
                                handleBrushIndexChange={handleBrushIndexChange}
                            />
                        }
                    </ChartCard>
                </Box>
            </Modal>
        </>
    )
}