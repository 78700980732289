import { Accordion, AccordionDetails, AccordionSummary, Avatar, Divider, Grid, List, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@emotion/react';
import * as React from 'react';
import { currencyRounded, percentageFormat, scientificFormat, tokenFormat } from "../utils/number-formats";
import { ChartCardWithModal } from "./chart-card-with-modal";
import { TokenAvatar } from "./token-avatar";
import { AddCard, AttachMoney, CardGiftcard, CurrencyExchange, PriceChange, TrendingUp } from "@mui/icons-material";
import { PortfolioMetricsListItem } from "./portfolio-metrics-list-item";
import { ChainIconButton } from "./chain-icon-button";
import { YYIconButton } from "./yy-icon-button";

const fieldMappings = {
    usd: {
        TVL: x => [currencyRounded(x.farmDepositTokenBalanceUSD)],
        listProps: [
            x => ({
                metrics: {
                    "Your value": x.userDepositTokenBalanceUSD,
                    "Net deposits": x.cumulativeNetDepositsUSD,
                    "Returns": x.userDepositTokenBalanceUSD - x.cumulativeNetDepositsUSD,
                    "Price changes / IL": x.cumulativeNetDepositsUSDAdjusted - x.cumulativeNetDepositsUSD,
                    "Farm rewards": x.userDepositTokenBalanceUSD - x.cumulativeNetDepositsUSDAdjusted,
                },
                displayIcon: { icon: color => <AttachMoney sx={{ color: color }} /> },
            }),
            x => ({
                metrics: {
                    "Simple return": x.simpleReturn,
                    "TWRR": x.timeWeightedRateOfReturn,
                }
            })
        ],
        chartProps: x => ({
            title: "Your value (USD)",
            data: x.timeSeriesData.map(({ dateTimestamp, userDepositTokenBalanceUSD, cumulativeNetDepositsUSDAdjusted, cumulativeNetDepositsUSD }) => (
                {
                    dateTimestamp,
                    "Value (USD)": userDepositTokenBalanceUSD,
                    "Net Deposits (USD)": cumulativeNetDepositsUSDAdjusted,
                    "Net Deposits (past USD value)": cumulativeNetDepositsUSD,
                }
            )),
            yAxisLineData: "Value (USD)",
            yAxisLineData2: "Net Deposits (USD)",
            yAxisSteppedLineData: "Net Deposits (past USD value)",
            valueFormat: currencyRounded,
        })
    },
    depositToken: {
        TVL: x => [tokenFormat(x.farmDepositTokenBalance, false, x.depositTokenSymbol)],
        listProps: [
            x => ({
                metrics: {
                    "Your value": x.userDepositTokenBalance,
                    "Net deposits": x.cumulativeNetDepositsAdjusted,
                    "Returns": x.userDepositTokenBalance - x.cumulativeNetDepositsAdjusted,
                    "Swap fees": x.cumulativeNetDeposits - x.cumulativeNetDepositsAdjusted,
                    "Other farm rewards": x.userDepositTokenBalance - x.cumulativeNetDeposits,
                },
                symbol: x.depositTokenSymbol,
                displayIcon: {
                    avatar: <TokenAvatar
                        tokenType={x.depositTokenType}
                        tokenInfo={x.depositTokenInfo}
                    />
                },
            }),
            x => ({
                metrics: {
                    "Simple return": x.simpleReturn,
                    "TWRR": x.timeWeightedRateOfReturn,
                }
            })
        ],
        chartProps: x => ({
            title: `Your value (${x.depositTokenSymbol})`,
            data: x.timeSeriesData.map(({ dateTimestamp, userDepositTokenBalance, cumulativeNetDepositsAdjusted, cumulativeNetDeposits }) => (
                {
                    dateTimestamp,
                    [`Value (${x.depositTokenSymbol})`]: userDepositTokenBalance,
                    [`Net Deposits (${x.depositTokenSymbol})`]: cumulativeNetDeposits,
                    [`Net Deposits (${x.depositTokenSymbol} - adjusted)`]: cumulativeNetDepositsAdjusted,
                }
            )),
            yAxisLineData: `Value (${x.depositTokenSymbol})`,
            yAxisLineData2: x.depositTokenType === "standardLP" ? `Net Deposits (${x.depositTokenSymbol} - adjusted)` : undefined,
            yAxisSteppedLineData: `Net Deposits (${x.depositTokenSymbol})`,
            valueFormat: scientificFormat,
        })
    },
    underlyingTokens: {
        TVL: x => [tokenFormat(x.farmDepositTokenBalanceToken0, false, x.depositTokenInfo.token0symbol), tokenFormat(x.farmDepositTokenBalanceToken1, false, x.depositTokenInfo.token1symbol)],
        listProps: [
            x => ({
                metrics: {
                    "Your value": x.userDepositTokenBalanceToken0,
                    "Net deposits": x.cumulativeNetDepositsToken0,
                    "Returns": x.userDepositTokenBalanceToken0 - x.cumulativeNetDepositsToken0,
                    "Price changes / IL": x.cumulativeNetDepositsToken0Adjusted - x.cumulativeNetDepositsToken0,
                    "Farm rewards": x.userDepositTokenBalanceToken0 - x.cumulativeNetDepositsToken0Adjusted,
                },
                symbol: x.depositTokenInfo.token0symbol,
                displayIcon: {
                    avatar: <Avatar src={x.depositTokenInfo.token0images.large} sx={{ bgcolor: 'white', width: 25, height: 25 }} />
                },
            }),
            x => ({
                metrics: {
                    "Your value": x.userDepositTokenBalanceToken1,
                    "Net deposits": x.cumulativeNetDepositsToken1,
                    "Returns": x.userDepositTokenBalanceToken1 - x.cumulativeNetDepositsToken1,
                    "Price changes / IL": x.cumulativeNetDepositsToken1Adjusted - x.cumulativeNetDepositsToken1,
                    "Farm rewards": x.userDepositTokenBalanceToken1 - x.cumulativeNetDepositsToken1Adjusted,
                },
                symbol: x.depositTokenInfo.token1symbol,
                displayIcon: {
                    avatar: <Avatar src={x.depositTokenInfo.token1images.large} sx={{ bgcolor: 'white', width: 25, height: 25 }} />
                },
            })
        ],
        chartProps: (x, chartDisplay) => ({
            title: `Your value (${x.depositTokenInfo[`token${chartDisplay}symbol`]})`,
            data: x.timeSeriesData.map(ts => (
                {
                    dateTimestamp: ts.dateTimestamp,
                    [`Value (${x.depositTokenInfo[`token${chartDisplay}symbol`]})`]: ts[`userDepositTokenBalanceToken${chartDisplay}`],
                    [`Net Deposits (${x.depositTokenInfo[`token${chartDisplay}symbol`]})`]: ts[`cumulativeNetDepositsToken${chartDisplay}`],
                    [`Net Deposits (${x.depositTokenInfo[`token${chartDisplay}symbol`]} - adjusted)`]: ts[`cumulativeNetDepositsToken${chartDisplay}Adjusted`],
                }
            )),
            yAxisLineData: `Value (${x.depositTokenInfo[`token${chartDisplay}symbol`]})`,
            yAxisLineData2: `Net Deposits (${x.depositTokenInfo[`token${chartDisplay}symbol`]} - adjusted)`,
            yAxisSteppedLineData: `Net Deposits (${x.depositTokenInfo[`token${chartDisplay}symbol`]})`,
            valueFormat: scientificFormat,
        })
    }
};

const listStructure = [
    {
        field: "Your value", leftPadding: 1, variant: "h6", startOpen: true, color: 'text.primary', nestedFields: [
            { field: "Net deposits", color: 'text.primary', icon: x => <AddCard sx={{ color: x }} /> },
            {
                field: "Returns", delta: true, icon: x => <TrendingUp sx={{ color: x }} />, nestedFields: [
                    { field: "Price changes / IL", delta: true, icon: x => <PriceChange sx={{ color: x }} /> },
                    { field: "Swap fees", delta: true, icon: x => <CurrencyExchange sx={{ color: x }} /> },
                    { field: "Farm rewards", delta: true, icon: x => <CardGiftcard sx={{ color: x }} /> },
                    { field: "Other farm rewards", delta: true, icon: x => <CardGiftcard sx={{ color: x }} /> },
                ]
            },
        ]
    },
    { field: "Simple return", leftPadding: 1, delta: true, icon: x => <TrendingUp sx={{ color: x }} /> },
    { field: "TWRR", leftPadding: 1, delta: true, icon: x => <TrendingUp sx={{ color: x }} /> },
];

export const PortfolioFarmAccordion = (props) => {
    const [displayIn, setDisplayIn] = React.useState("usd");
    const [lpChartDisplay, setLPChartDisplay] = React.useState("0");
    const [expand, setExpand] = React.useState(false);

    const { data, handleUpdateData } = props;
    const {
        farmName, farmAddress, depositTokenLabel, depositTokenSymbol, depositTokenAddress, depositTokenType, depositTokenInfo, farmDepositTokenBalance,
        farmDepositTokenBalanceUSD, APY, userDepositTokenBalance, userDepositTokenBalanceUSD, cumulativeNetDeposits, cumulativeNetDepositsAdjusted,
        cumulativeNetDepositsUSD, cumulativeNetDepositsUSDAdjusted, simpleReturn, timeWeightedRateOfReturn, timeSeriesData
    } = data;

    const handleToggleButtonChange = (event, newDisplayIn) => {
        if (newDisplayIn) { // Have this line because clicking when already selected returns undefined.
            setDisplayIn(newDisplayIn);
        };
    };

    const handleLPChartToggleButtonChange = (event, newLPChartDisplay) => {
        if (newLPChartDisplay) { // Have this line because clicking when already selected returns undefined.
            setLPChartDisplay(newLPChartDisplay);
        };
    };

    const toggleAccordion = () => {
        setExpand((prev) => !prev);
    };

    const theme = useTheme();

    return (
        <Accordion expanded={expand} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon onClick={toggleAccordion} sx={{ color: "primary.main" }} fontSize="large" />}
            >
                <Grid container alignItems="center">
                    <Grid item xs={5} sm={4} md={3}>
                        <Grid container alignItems="flex-start" spacing={1}>
                            <Grid item xs={12} container justifyContent="center">
                                <ToggleButtonGroup value={displayIn} size="small" exclusive onChange={handleToggleButtonChange} sx={{ backgroundColor: theme.palette.secondary.main }}>
                                    <ToggleButton value="usd">USD</ToggleButton>
                                    <ToggleButton value="depositToken">{depositTokenSymbol}</ToggleButton>
                                    {
                                        depositTokenType === "standardLP"
                                            ? <ToggleButton value="underlyingTokens">{depositTokenInfo.token0symbol} / {depositTokenInfo.token1symbol}</ToggleButton>
                                            : ""
                                    }
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <TokenAvatar
                                    tokenType={depositTokenType}
                                    tokenInfo={depositTokenInfo}
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <Typography variant="h6">
                                    {farmName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
                                    <YYIconButton
                                        address={farmAddress}
                                        tooltipTitle="View farm on Yield Yak"
                                    />
                                    <ChainIconButton
                                        address={farmAddress}
                                        tooltipTitle={(tracker) => `View farm on ${tracker}`}
                                    />
                                    <ChainIconButton
                                        address={depositTokenAddress}
                                        addressOrToken="token"
                                        tooltipTitle={(tracker) => `View deposit token on ${tracker} - ${depositTokenLabel}`}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-end" alignItems="flex-start">
                                <Typography variant="caption">
                                    TVL
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container>
                                {
                                    fieldMappings[displayIn].TVL(data).map(
                                        (item, num) => (
                                            <Grid item xs={12} container justifyContent="flex-start" key={num}>
                                                <Typography variant="caption">
                                                    {item}
                                                </Typography>
                                            </Grid>
                                        )
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-end">
                                <Typography variant="caption">
                                    APY
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-start">
                                <Typography variant="caption">
                                    {percentageFormat(APY)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider orientation="vertical" color="primary" flexItem style={{ marginRight: "-1px" }} />
                    <Grid item xs={7} sm={8} md={9} container alignItems="flex-start" sx={{ pl: 1 }}>
                        {
                            fieldMappings[displayIn].listProps.map(
                                lp => lp(data)
                            ).map(
                                ({ metrics, symbol, displayIcon }, num) => (
                                    <Grid item xs={12} sm={12 / fieldMappings[displayIn].listProps.length} key={num}>
                                        <List
                                            dense
                                            disablePadding
                                            sx={{ bgcolor: 'background.paper' }}>
                                            {
                                                listStructure.map(
                                                    ({ field, delta, leftPadding, icon, variant, startOpen, color, nestedFields }) => (
                                                        <PortfolioMetricsListItem
                                                            key={field}
                                                            displayIn={displayIn}
                                                            depositTokenType={depositTokenType}
                                                            metrics={metrics}
                                                            symbol={symbol}
                                                            icon={icon}
                                                            {...displayIcon}
                                                            field={field}
                                                            delta={delta}
                                                            leftPadding={leftPadding}
                                                            variant={variant}
                                                            startOpen={startOpen}
                                                            color={color}
                                                            nestedFields={nestedFields}
                                                        />
                                                    )
                                                )
                                            }
                                        </List>
                                    </Grid>
                                )
                            )
                        }
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} md={3} container alignItems="center" spacing={1}>
                        <Grid item xs={6} md={12}>
                            <ChartCardWithModal
                                chartType="line"
                                chartHeight={100}
                                initialLookBack={-1}
                                title="Overall Farm APY"
                                data={timeSeriesData}
                                yAxisLineData="APY"
                                xAxisData="dateTimestamp"
                                valueFormat={percentageFormat}
                                handleUpdateData={handleUpdateData}
                                isMiniChart
                            />
                        </Grid>
                        <Grid item xs={6} md={12}>
                            <ChartCardWithModal
                                chartType="line"
                                chartHeight={100}
                                initialLookBack={-1}
                                title={
                                    displayIn === "usd"
                                        ? "Overall Farm TVL"
                                        : displayIn === "depositToken"
                                            ? `Overall Farm Balance (${depositTokenSymbol})`
                                            : `Farm Balance (${data.depositTokenInfo[`token${lpChartDisplay}symbol`]})`
                                }
                                data={timeSeriesData}
                                yAxisLineData={
                                    displayIn === "usd"
                                        ? "farmDepositTokenBalanceUSD"
                                        : displayIn === "depositToken"
                                            ? "farmDepositTokenBalance"
                                            : `farmDepositTokenBalanceToken${lpChartDisplay}`
                                }
                                xAxisData="dateTimestamp"
                                valueFormat={displayIn === "usd" ? currencyRounded : scientificFormat}
                                handleUpdateData={handleUpdateData}
                                isMiniChart
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={9} container>
                        {
                            displayIn === "underlyingTokens" ?
                                <Grid item xs={12}>
                                    <ToggleButtonGroup value={lpChartDisplay} size="small" exclusive onChange={handleLPChartToggleButtonChange} sx={{ backgroundColor: theme.palette.info.main }}>
                                        <ToggleButton value={"0"}>{data.depositTokenInfo.token0symbol}</ToggleButton>
                                        <ToggleButton value={"1"}>{data.depositTokenInfo.token1symbol}</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                                : ''
                        }
                        <Grid item xs={12}>
                            <ChartCardWithModal
                                chartType="portfolio"
                                chartHeight={250}
                                initialLookBack={-1}
                                {...fieldMappings[displayIn].chartProps(data, lpChartDisplay)}
                                xAxisData="dateTimestamp"
                                handleUpdateData={handleUpdateData}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}