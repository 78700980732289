import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Backdrop, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar, sidebarItems } from './dashboard-sidebar';
import { AppDataContext } from '../utils/app-data-context';
import { useTheme } from '@emotion/react';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

export const DashboardLayout = (props) => {
  const { chain } = React.useContext(AppDataContext);
  const { children } = props;
  let location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = React.useState(false);

  const theme = useTheme();
  
  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {children}
          <Backdrop
            open={!(sidebarItems.find(({ href }) => (location.pathname.indexOf("/", 1) === -1 ? location.pathname : location.pathname.slice(0, Math.max(1, location.pathname.indexOf("/", 1)))) === href)?.chains || []).includes(chain)}
            sx={{
              zIndex: (theme) => theme.zIndex.appBar - 50,
              [theme.breakpoints.up('lg')]: {
                paddingLeft: 36,
              },
            }}
          >
            <Box sx={{ width: 200, backgroundColor: theme.palette.background.paper, padding: 2, border: '1px solid', borderColor: theme.palette.primary.main }}>
              <Typography variant='subtitle2' sx={{ color: theme.palette.text.primary }}>Data not yet available for {chain} for this page</Typography>
            </Box>
          </Backdrop>
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </>
  );
};