import { Box, Chip, Container, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Storage } from 'aws-amplify';
import * as React from 'react';
import { feesColumnsModel } from '../utils/column-visibility-models';
import { CustomDivider } from '../components/custom-divider';
import { AppDataContext } from '../utils/app-data-context';
import { YakStyledTable } from '../components/yak-styled-table';
import { feesTableColumns } from '../utils/table-columns';

export default function Fees() {
    const { chain } = React.useContext(AppDataContext);
    const [dataLatest, setDataLatest] = React.useState([]);
    const [personalReinvestFrequency, setPersonalReinvestFrequency] = React.useState(1);
    const [personalTVL, setPersonalTVL] = React.useState(1000);
    const [personalTimeSpentPerReinvest, setPersonalTimeSpentPerReinvest] = React.useState(5);
    const [personalHourlyRate, setPersonalHourlyRate] = React.useState(50);
    const [dataToDisplay, setDataToDisplay] = React.useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(feesColumnsModel);

    React.useEffect(() => {
        handleRefreshFileData();
    }, [chain]);

    React.useEffect(() => {
        updateCalculatedValues();
    }, [dataLatest, personalReinvestFrequency, personalTVL, personalTimeSpentPerReinvest, personalHourlyRate]);

    const handleRefreshFileData = async () => {
        setDataLatest([]);
        const latestStorageInfo = await Storage.get(`${chain}/agg-data/latestFarmData.json`, { download: true });
        let newDataLatest = await new Response(latestStorageInfo.Body).json();
        newDataLatest = newDataLatest.map(({ farmName, farmAddress, depositTokenBalanceUSD, APY, adminFee, devFee, reinvestReward, reinvestFrequency, gasCostUSDPerReinvest }) => (
            {
                farmName,
                farmAddress,
                depositTokenBalanceUSD,
                APY,
                adminFee,
                devFee,
                reinvestReward,
                reinvestFrequency,
                gasCostUSDPerReinvest,
                APR: reinvestFrequency > 0 ? (reinvestFrequency / (1 - reinvestReward - devFee - adminFee)) * ((1 + APY) ** (1 / reinvestFrequency) - 1) : undefined,
            }
        )).map(({ APR, adminFee, devFee, reinvestReward, ...others }) => (
            {
                ...others,
                adminFee,
                devFee,
                reinvestReward,
                APR,
                APYMax: APR && (Math.exp(APR * (1 - reinvestReward - devFee - adminFee)) - 1),
            }
        ));
        setDataLatest(newDataLatest);
    }

    const handleColumnVisibilityModelChange = (newColumnVisibilityModel) => {
        setColumnVisibilityModel(newColumnVisibilityModel);
    }

    const updateCalculatedValues = () => {
        const newDataToDisplay = dataLatest.map(({ APR, gasCostUSDPerReinvest, ...others }) => (
            {
                ...others,
                gasCostUSDPerReinvest,
                APR,
                APYPersonal: APR && (1 + APR / Number(personalReinvestFrequency)) ** Number(personalReinvestFrequency) - 1 - (gasCostUSDPerReinvest + (Number(personalTimeSpentPerReinvest) / 60) * Number(personalHourlyRate)) * Number(personalReinvestFrequency) / Number(personalTVL),
            }
        )).map(({ APY, APYPersonal, ...others }) => (
            {
                ...others,
                APY,
                APYPersonal,
                benefitFromYY: APYPersonal && (APY - APYPersonal)
            }
        ));
        setDataToDisplay(newDataToDisplay);
    }

    const theme = useTheme();

    const convertToValidNumber = (value) => {
        const newValue = value
            .replace(/[^\d\.]/g, '')
            .replace('.', 'dot')
            .replace(/\./g, '')
            .replace('dot', '.')
            .replace(/^0+/, '')
            .replace(/^\./, '0.');

        return (newValue === '' || newValue === '.') ? 0 : newValue;
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
            <Container maxWidth={false}>
                <CustomDivider label={"ABOUT"} />
                <Typography>
                    <p>
                        This basic tool should give you more information about your returns when investing through Yield Yak vs. "DIY".
                    </p>
                    <p>
                        The biggest benefits of using Yield Yak are auto-compounding your position, saving gas costs, and saving time.
                    </p>
                    <p>
                        In the below, we quantify the trade-off between the fee charged by Yield Yak and the added costs of the "DIY" approach based on your personal inputs.
                    </p>
                    <p>
                        NOT FINANCIAL ADVICE.
                    </p>
                </Typography>
                <CustomDivider label={"YOUR INPUTS"} />
                <Grid container spacing={2}>
                    {
                        [
                            { label: "Personal # reinvests / year", value: personalReinvestFrequency, setValue: setPersonalReinvestFrequency, helperText: "How many times would you manually reinvest your rewards per year?" },
                            { label: "Personal TVL", value: personalTVL, setValue: setPersonalTVL, InputProps: { startAdornment: <InputAdornment position="start"><Typography color='primary.main'>$</Typography></InputAdornment> }, helperText: "How much do you personally hold in the pool?" },
                            { label: "Personal time spent per reinvest", value: personalTimeSpentPerReinvest, setValue: setPersonalTimeSpentPerReinvest, InputProps: { endAdornment: <InputAdornment position="end"><Typography color='primary.main'>minutes</Typography></InputAdornment> }, helperText: "How long would it take to manually reinvest your position?" },
                            { label: "Personal value", value: personalHourlyRate, setValue: setPersonalHourlyRate, InputProps: { startAdornment: <InputAdornment position="start"><Typography color='primary.main'>$</Typography></InputAdornment>, endAdornment: <InputAdornment position="end"><Typography color='primary.main'>/ hour</Typography></InputAdornment> }, helperText: "How much is your time worth?" },
                        ].map(({ setValue, ...others }) => (
                            <TextField
                                key={others.label}
                                focused
                                {...others}
                                onChange={(event) => setValue(convertToValidNumber(event.target.value))}
                                sx={{ m: 1, width: '25ch' }}
                                FormHelperTextProps={{ style: { color: theme.palette.text.primary } }}
                            />
                        ))
                    }
                </Grid>
                <CustomDivider label={"FEE HEALTH SNAPSHOT"} />
                <YakStyledTable
                    rows={dataToDisplay}
                    getRowId={(row) => row.farmAddress}
                    columns={feesTableColumns}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                    initialState={{
                        pagination: {
                            pageSize: 10,
                        },
                        sorting: {
                            sortModel: [{ field: 'depositTokenBalanceUSD', sort: 'desc' }],
                        },
                        filter: {
                            filterModel: {
                                items: [{ columnField: 'reinvestFrequency', operatorValue: '>', value: 0 }]
                            }
                        }
                    }}
                />
                <Typography variant='caption'>* these values are estimated based on gas costs and the number of reinvests in the last 30 days.</Typography>
            </Container>
        </Box>
    )
}