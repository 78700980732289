import * as React from 'react';
import { Storage } from 'aws-amplify';
import { Avatar, Box, Container, FormControlLabel, Grid, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Switch, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { SunburstChart } from '../components/sunburst';
import { CustomDivider } from '../components/custom-divider';
import { ChartCardWithModal } from '../components/chart-card-with-modal';
import { scientificFormat, integerWithCommas, tokenFormat } from '../utils/number-formats';
import { useTheme } from '@emotion/react';
import * as d3 from "d3";
import { yakOwnershipColumnsModel } from '../utils/column-visibility-models';
import { SingleValueCard } from '../components/single-value-card';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AppDataContext } from '../utils/app-data-context';
import { chainInfo } from '../components/chain-icon-button';
import { yakOwnershipColumns } from '../utils/table-columns';
import { YakStyledTable } from '../components/yak-styled-table';

export default function YakToken() {
    const { chain } = React.useContext(AppDataContext);
    const [currentBalancesDataArray, setCurrentBalancesDataArray] = React.useState([]);
    const [currentBalancesData, setCurrentBalancesData] = React.useState({});
    const [tokensOverTimeData, setTokensOverTimeData] = React.useState([]);
    const [latestTimestamp, setLatestTimestamp] = React.useState(0);
    const [balanceChangesData, setBalanceChangesData] = React.useState({});
    const [recentYakSwapsData, setRecentYakSwapsData] = React.useState({});
    const [distinctTokenPositions, setDistinctTokenPositions] = React.useState([]);
    const [groupEOAContractPositions, setGroupEOAContractPositions] = React.useState(false);
    const [changesTimePeriod, setChangesTimePeriod] = React.useState("1Day");
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({ ...yakOwnershipColumnsModel, yakBalanceChange1Day: true });
    const [swapsTimePeriod, setSwapsTimePeriod] = React.useState(1);
    const [swapsPageNumber, setSwapsPageNumber] = React.useState(1);

    const theme = useTheme();

    const { tracker } = chainInfo[chain];

    const YAK_TOKEN_ADDRESSES = {
        avalanche: "0x59414b3089ce2af0010e7523dea7e2b35d776ec7",
        arbitrum: "0x7f4db37d7beb31f445307782bc3da0f18df13696"
    };

    React.useEffect(() => {
        handleRefreshFileData();
    }, [chain]);

    React.useEffect(() => {
        handleToggleGroupPositions();
    }, [groupEOAContractPositions]);

    const handleRefreshFileData = async () => {
        setCurrentBalancesDataArray([]);
        setCurrentBalancesData({});
        setTokensOverTimeData([]);
        setBalanceChangesData({});
        setRecentYakSwapsData({});
        setDistinctTokenPositions([]);
        // First deal with the data for current balances that is used in the sunburst.
        const currentBalancesDataStorageInfo = await Storage.get(`${chain}/agg-yak-token-related-data/currentBalances.json`, { download: true });
        const newCurrentBalancesDataArray = await new Response(currentBalancesDataStorageInfo.Body).json();
        setCurrentBalancesDataArray(newCurrentBalancesDataArray);
        handleToggleGroupPositions(newCurrentBalancesDataArray);
        // Next we pull the information for changes to Yak ownership over time.
        const tokensOverTimeDataStorageInfo = await Storage.get(`${chain}/agg-yak-token-related-data/tokensOverTime.json`, { download: true });
        const newTokensOverTimeDataRaw = await new Response(tokensOverTimeDataStorageInfo.Body).json();
        // Now manipulate the tokensOverTimeData into the correct format
        const newTokensOverTimeData = [];
        newTokensOverTimeDataRaw.reduce(
            (result, row) => {
                const { dateTimestamp, yakBalance, ...otherFields } = row;
                if (!result[dateTimestamp]) {
                    result[dateTimestamp] = ({ dateTimestamp });
                    newTokensOverTimeData.push(result[dateTimestamp]);
                };
                result[dateTimestamp][JSON.stringify(otherFields)] = yakBalance;
                return result;
            }, {}
        );
        const newDistinctTokenPositions = [...new Set(newTokensOverTimeDataRaw.map(({ dateTimestamp, yakBalance, ...otherFields }) => JSON.stringify(otherFields)))];
        setDistinctTokenPositions(newDistinctTokenPositions.map((key, num) => {
            const otherFields = JSON.parse(key);
            const label = [1, 2, 3, 4].map(i => (
                [
                    otherFields[`symbol${i}`] && `Deposited into ${otherFields[`symbol${i}`]}`,
                    otherFields[`stakingContractAddressName${i}`] && `Staked with ${otherFields[`stakingContractAddressName${i}`]}`
                ]
            )).reduce((a, b) => a.concat(b)).filter(x => x).join(" / ") || (otherFields.other ? "Other" : "Spot Yak")
            let color;
            if (label === "Spot Yak") {
                color = theme.palette.avax.main;
            } else if (label === "Other") {
                color = theme.palette.text.disabled;
            } else if (label === "Staked with MasterYak") {
                color = theme.palette.primary.main;
            } else if (label === "Deposited into YRT / Staked with MasterYak") {
                color = theme.palette.info.main;
            } else if (label.match(/PGL|Pangolin/)) {
                color = '#ffc800';
            } else if (label.match(/JLP|TraderJoe/)) {
                color = '#f2716a';
            } else if (label.match(/Glacier/)) {
                color = '#e619f9';
            } else {
                color = d3.interpolateRainbow(num / newDistinctTokenPositions.length);
            }
            return ({ key, label, color });
        }
        ));
        setTokensOverTimeData(newTokensOverTimeData);
        const newLatestTimestamp = newTokensOverTimeData.slice(-1)[0]?.dateTimestamp + 86400
        setLatestTimestamp(newLatestTimestamp);
        // Next we get the info related to recent changes to Yak ownership to spot the gainers and losers.
        const balanceChangesStorageInfo = await Storage.get(`${chain}/agg-yak-token-related-data/balanceChanges.json`, { download: true });
        const balanceChangesDataRaw = await new Response(balanceChangesStorageInfo.Body).json();
        const balancesLength = balanceChangesDataRaw.length;
        const timePeriods = ["1Day", "7Days", "30Days", "90Days"];
        const lgs = ["losses", "gains"];
        const excludeCols = timePeriods.map(tp => lgs.map(lg => `${lg}${tp}Ranking`).concat(`yakBalanceChange${tp}`)).reduce((a, b) => a.concat(b));
        const newBalanceChangesData = timePeriods.reduce(
            (result, timePeriod) => {
                result[timePeriod] = lgs.reduce(
                    (res, lg) => {
                        res[lg] = [...new Array(Math.min(10, balancesLength)).keys()].map(a => a + 1).map(
                            rank => {
                                const filteredData = balanceChangesDataRaw.filter(d => d[`${lg}${timePeriod}Ranking`] === rank);
                                const { holderAddress, holderAddressName, holderAddressType, yakBalanceChange1Day, yakBalanceChange7Days, yakBalanceChange30Days, yakBalanceChange90Days } = filteredData[0];
                                const before = filteredData.filter(({ daysToLatest }) => daysToLatest === timePeriod).map(d => Object.keys(d).reduce((r, row) => { if (!excludeCols.includes(row)) r[row] = d[row]; return r }, {}));
                                const after = filteredData.filter(({ daysToLatest }) => daysToLatest === "0Days").map(d => Object.keys(d).reduce((r, row) => { if (!excludeCols.includes(row)) r[row] = d[row]; return r }, {}));
                                return ({
                                    holderAddress,
                                    holderAddressName: holderAddressName || holderAddress,
                                    holderAddressType,
                                    yakBalanceChange1Day,
                                    yakBalanceChange7Days,
                                    yakBalanceChange30Days,
                                    yakBalanceChange90Days,
                                    previousBalance: before.reduce((total, row) => total + row.yakBalance, 0),
                                    newBalance: after.reduce((total, row) => total + row.yakBalance, 0),
                                    before,
                                    after,
                                });
                            }
                        );
                        return res;
                    }, {}
                );
                return result;
            }, {}
        );
        setBalanceChangesData(newBalanceChangesData);
        // Next we pull the information for recent swaps of the Yak token made through Yak Swap.
        const recentYakSwapsStorageInfo = await Storage.get(`${chain}/agg-yak-token-related-data/recentYakSwaps.json`, { download: true });
        const recentYakSwapsDataRaw = await new Response(recentYakSwapsStorageInfo.Body).json();
        const newRecentYakSwapsData = [1, 7, 30, 90].reduce(
            (result, days) => {
                const swaps = recentYakSwapsDataRaw.filter(({ blockTimestamp }) => blockTimestamp >= newLatestTimestamp - 86400 * days);
                const swapsIn = swaps.filter(s => s.tokenIn === YAK_TOKEN_ADDRESSES[chain]);
                const swapsOut = swaps.filter(s => s.tokenOut === YAK_TOKEN_ADDRESSES[chain]);
                result[days] = {
                    numberOfSwapsFromYak: swapsIn.length,
                    yakAmountIn: swapsIn.reduce((a, b) => a + b.amountIn, 0),
                    numberOfSwapsToYak: swapsOut.length,
                    yakAmountOut: swapsOut.reduce((a, b) => a + b.amountOut, 0),
                    swaps
                };
                return result;
            }, {}
        );
        setRecentYakSwapsData(newRecentYakSwapsData);
    };

    const handleToggleGroupPositions = (dataArray = currentBalancesDataArray) => {
        const newCurrentBalancesData = dataArray.reduce(
            (result, row) => {
                const { holderAddress, holderAddressName, holderAddressType, yakBalance } = row;
                // Create an array of ownership that we work through in order to create the nested object.
                // We keep other information in here also that is used in the sunburst chart.
                let ownershipArray = [4, 3, 2, 1].map(i => (
                    [
                        {
                            name: row[`userAddress${i}`],
                            label: row[`userAddressName${i}`] || (row[`symbol${i}`] ? `${row[`symbol${i}`]} (${row[`userAddress${i}`]})` : row[`userAddress${i}`]),
                            title: (row[`symbol${i}`] ? `deposited into <a href="https://${tracker}.io/address/${row[`userAddress${i}`]}" target="_blank" rel="noreferrer">${row[`symbol${i}`]} (${row[`userAddress${i}`]?.slice(0, 6)}...)</a>` : `owned by <a href="https://${tracker}.io/address/${row[`userAddress${i}`]}" target="_blank" rel="noreferrer">${row[`userAddressName${i}`] || `${row[`userAddress${i}`]?.slice(0, 6)}...`} (${row[`addressType${i}`]}${row[`userAddressName${i}`] ? ` - ${row[`userAddress${i}`]?.slice(0, 6)}...` : ''})</a>`)
                        },
                        {
                            name: row[`stakingContractAddress${i}`],
                            label: row[`stakingContractAddressName${i}`] || row[`stakingContractAddress${i}`],
                            title: `staked with <a href="https://${tracker}.io/address/${row[`stakingContractAddress${i}`]}" target="_blank" rel="noreferrer">${row[`stakingContractAddressName${i}`]} (${row[`stakingContractAddress${i}`]?.slice(0, 6)}...)</a>`
                        }
                    ]
                )).reduce((a, b) => a.concat(b)).filter(a => a.name);
                if (groupEOAContractPositions) {
                    ownershipArray = ownershipArray.concat(
                        {
                            name: holderAddress,
                            label: holderAddressName || holderAddress,
                            title: `owned by <a href="https://${tracker}.io/address/${holderAddress}" target="_blank" rel="noreferrer">${holderAddressName || holderAddress.slice(0, 6)} (${holderAddressType}${holderAddressName ? ` - ${holderAddress.slice(0, 6)}` : ''})</a>`
                        }
                    )
                };
                let children = result.children;
                while (ownershipArray.length > 0) {
                    const { name, label, title } = ownershipArray.pop();
                    if (!children.some(child => child.name === name)) {
                        if (ownershipArray.length > 0) {
                            children.push({ name, label, title, children: [] });
                        } else {
                            children.push({ name, label, title, yakBalance });
                        }
                    };
                    children = children.find(child => child.name === name).children;
                };
                return result;
            }
            , { "name": "Total Supply", label: "Total Supply", title: "Total Supply", children: [] }
        );
        setCurrentBalancesData(newCurrentBalancesData);
    };

    const handleUpdateData = () => {
        // This line is a hacky way to force the chart to re-render (otherwise the brush doesn't update).
        // We need the .concat([]), else it doesn't see a change so doesn't re-render.
        setTokensOverTimeData([...tokensOverTimeData]);
    };

    const handleChangesToggleButtonChange = (event, newChangesTimePeriod) => {
        if (!newChangesTimePeriod) {
            // do nothing in this case (clicking on the already active changesTimePeriod makes newChangesTimePeriod null)
            return;
        }
        setChangesTimePeriod(newChangesTimePeriod);
        setColumnVisibilityModel({ ...yakOwnershipColumnsModel, [`yakBalanceChange${newChangesTimePeriod}`]: true });
    };

    const handleColumnVisibilityModelChange = (newColumnVisibilityModel) => {
        setColumnVisibilityModel(newColumnVisibilityModel);
    };

    const handleSwapsToggleButtonChange = (event, newSwapsTimePeriod) => {
        if (!newSwapsTimePeriod) {
            // do nothing in this case (clicking on the already active changesTimePeriod makes newChangesTimePeriod null)
            return;
        }
        setSwapsTimePeriod(newSwapsTimePeriod);
        setSwapsPageNumber(1);
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
            <Container maxWidth={false}>
                <CustomDivider label={`TOKEN OWNERSHIP AS OF ${latestTimestamp > 0 ? `${new Date(1000 * latestTimestamp).toISOString().slice(0, 10)} 00:00:00` : 'YESTERDAY'}`} />
                <Grid container spacing={3} alignItems={"center"} sx={{ mb: 5 }}>
                    <Grid item xs={12} container justifyContent={"center"}>
                        <FormControlLabel control={<Switch checked={groupEOAContractPositions} onChange={(event) => setGroupEOAContractPositions(event.target.checked)} />} label="Group by ultimate owner first" />
                    </Grid>
                    <Grid item xs={12} container justifyContent={"center"} id="sunburstParent">
                        {
                            currentBalancesData.children?.length > 0 ?
                                <SunburstChart data={currentBalancesData} tracker={tracker} chain={chain} groupEOAContractPositions={groupEOAContractPositions} /> :
                                <Skeleton variant="circular" width={"min(70vh, 75vw)"} height={"min(70vh, 75vw)"} />
                        }
                    </Grid>
                </Grid>
                <CustomDivider label="TOKEN OWNERSHIP OVER TIME" />
                <Grid container spacing={3} alignItems={"center"} sx={{ mb: 5 }}>
                    <Grid item xs={12}>
                        <ChartCardWithModal
                            initialLookBack={30}
                            chartType="stacked bar"
                            title="Yak Token Over Time"
                            data={tokensOverTimeData}
                            extraData={distinctTokenPositions}
                            yAxisLineData="yakBalance"
                            xAxisData="dateTimestamp"
                            valueFormat={scientificFormat}
                            handleUpdateData={handleUpdateData}
                            chartHeight={400}
                        />
                    </Grid>
                </Grid>
                <CustomDivider label="RECENT CHANGES TO YAK OWNERSHIP" />
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                    <Grid item xs={12} container justifyContent="center">
                        <ToggleButtonGroup value={changesTimePeriod} size="small" exclusive onChange={handleChangesToggleButtonChange} sx={{ backgroundColor: theme.palette.secondary.main, background: "linear-gradient(#553f77, #303030)" }}>
                            <ToggleButton value="1Day">1 day</ToggleButton>
                            <ToggleButton value="7Days">7 days</ToggleButton>
                            <ToggleButton value="30Days">30 days</ToggleButton>
                            <ToggleButton value="90Days">90 days</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    {
                        [
                            { label: "increases", key: "gains" },
                            { label: "decreases", key: "losses" }
                        ].map(({ label, key }) => (
                            <Grid key={key} item xs={12} lg={6} container spacing={3}>
                                <Grid item xs={12} container justifyContent={"center"}>
                                    <Typography variant='subtitle2'>
                                        BIGGEST <span style={{ color: key === "gains" ? theme.palette.primary.main : theme.palette.avax.main }}>{label.toUpperCase()}</span> IN YAK BALANCE
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <YakStyledTable
                                        rows={balanceChangesData[changesTimePeriod]?.[key] || []}
                                        getRowId={(row) => row.holderAddress}
                                        columns={yakOwnershipColumns}
                                        columnVisibilityModel={columnVisibilityModel}
                                        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                                    />
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
                <CustomDivider label="RECENT YAK TOKEN YAK SWAPS" />
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                    <Grid item xs={12} container justifyContent="center">
                        <ToggleButtonGroup value={swapsTimePeriod} size="small" exclusive onChange={handleSwapsToggleButtonChange} sx={{ backgroundColor: theme.palette.secondary.main, background: "linear-gradient(#553f77, #303030)" }}>
                            <ToggleButton value={1}>1 day</ToggleButton>
                            <ToggleButton value={7}>7 days</ToggleButton>
                            <ToggleButton value={30}>30 days</ToggleButton>
                            <ToggleButton value={90}>90 days</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center" spacing={3} sx={{ mb: 3 }}>
                        {
                            [
                                { label: "# Swaps from YAK token", value: recentYakSwapsData[swapsTimePeriod]?.numberOfSwapsFromYak, valueFormat: integerWithCommas },
                                { label: "YAK \"sold\" through Yak Swap", value: recentYakSwapsData[swapsTimePeriod]?.yakAmountIn, valueFormat: scientificFormat },
                                { label: "# Swaps to YAK token", value: recentYakSwapsData[swapsTimePeriod]?.numberOfSwapsToYak, valueFormat: integerWithCommas },
                                { label: "YAK \"bought\" through Yak Swap", value: recentYakSwapsData[swapsTimePeriod]?.yakAmountOut, valueFormat: scientificFormat },
                            ].map(({ label, value, valueFormat }) => (
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={2} key={label}>
                                    <SingleValueCard
                                        label={label}
                                        value={value}
                                        valueFormat={valueFormat}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid item xs={12} container justifyContent="center" alignItems="center" spacing={3}>
                        <Typography>
                            Showing {(swapsPageNumber - 1) * 10 + 1}-{Math.min(swapsPageNumber * 10, (recentYakSwapsData[swapsTimePeriod]?.swaps || []).length)} of {(recentYakSwapsData[swapsTimePeriod]?.swaps || []).length}
                        </Typography>
                        <IconButton onClick={() => setSwapsPageNumber(Math.max(1, swapsPageNumber - 1))}>
                            <KeyboardArrowLeftIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={() => setSwapsPageNumber(Math.min(Math.ceil((recentYakSwapsData[swapsTimePeriod]?.swaps || []).length / 10), swapsPageNumber + 1))}>
                            <KeyboardArrowRightIcon color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center" spacing={3}>
                        <List>
                            {
                                (recentYakSwapsData[swapsTimePeriod]?.swaps || []).slice((swapsPageNumber - 1) * 10, swapsPageNumber * 10).map(
                                    (swap, num) => (
                                        <ListItem key={num} sx={{ backgroundColor: theme.palette.background.paper, margin: 2 }} >
                                            <ListItemText
                                                primary={
                                                    <Typography>
                                                        Tx <Link href={`https://${tracker}.io/tx/${swap.transactionHash}`} target="_blank" rel="noreferrer">
                                                            {swap.transactionHash.slice(0, 7)}...
                                                        </Link> : Address <Link href={`https://${tracker}.io/address/${swap.traderAddress}`} target="_blank" rel="noreferrer">
                                                            {swap.traderAddress.slice(0, 7)}...
                                                        </Link> swapped
                                                    </Typography>
                                                }
                                            />
                                            <ListItemText primary={` ${tokenFormat(swap.amountIn, false, swap.tokenInSymbol)}`} />
                                            <ListItemAvatar>
                                                <IconButton href={`https://${tracker}.io/token/${swap.tokenIn}`} target="_blank" rel="noreferrer">
                                                    <Avatar src={JSON.parse(swap.tokenInImage || '{}').small} sx={{ height: 25, width: 25 }}>
                                                        ?
                                                    </Avatar>
                                                </IconButton>
                                            </ListItemAvatar>
                                            <ListItemText primary={"for "} sx={{ mr: 3 }} />
                                            <ListItemText primary={tokenFormat(swap.amountOut, false, swap.tokenOutSymbol)} />
                                            <ListItemAvatar>
                                                <IconButton href={`https://${tracker}.io/token/${swap.tokenOut}`} target="_blank" rel="noreferrer">
                                                    <Avatar src={JSON.parse(swap.tokenOutImage || '{}').small} sx={{ height: 25, width: 25 }}>
                                                        ?
                                                    </Avatar>
                                                </IconButton>
                                            </ListItemAvatar>
                                        </ListItem>
                                    )
                                )
                            }
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
};