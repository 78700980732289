import * as React from 'react';
import { IconButton, Tooltip } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const YYIconButton = (props) => {
    const { address, tooltipTitle = 'View on Yield Yak' } = props;
    return (
        <Tooltip title={tooltipTitle}>
            <IconButton href={`https://yieldyak.com/farms/detail/${address}`} target="_blank" rel="noreferrer">
                <OpenInNewIcon color="primary" fontSize='small' />
            </IconButton>
        </Tooltip>
    );
};
