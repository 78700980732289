import { Box, Container, Grid } from '@mui/material';
import { currencyRounded, integerWithCommas, percentageFormat } from '../utils/number-formats';
import { useTheme } from '@emotion/react';
import { Storage } from 'aws-amplify';
import * as React from 'react';
import { ChartCardWithModal } from '../components/chart-card-with-modal';
import { CardCarousel } from '../components/card-carousel';
import { CustomDivider } from '../components/custom-divider';
import { AppDataContext } from '../utils/app-data-context';

export default function Home() {
    const { chain } = React.useContext(AppDataContext);

    const [dailyData, setDailyData] = React.useState([]);
    const [extraData, setExtraData] = React.useState({});

    React.useEffect(() => {
        handleRefreshFileData();
    }, [chain]);

    const handleRefreshFileData = async () => {
        setDailyData([]);
        setExtraData([]);
        const dailyDataStorageInfo = await Storage.get(`${chain}/agg-data/dailyData.json`, { download: true });
        const newDailyData = await new Response(dailyDataStorageInfo.Body).json();
        setDailyData(newDailyData);
        const newExtraData = { latestDate: newDailyData[newDailyData.length - 1].date };
        setExtraData(newExtraData);
    };

    const handleUpdateData = () => {
        // This line is a hacky way to force the chart to re-render (otherwise the brush doesn't update).
        // We need the .concat([]), else it doesn't see a change so doesn't re-render.
        setDailyData(dailyData.concat([]));
    };

    const theme = useTheme();

    return (
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
            <Container maxWidth={false}>
                <CustomDivider label={`DATA FOR ${extraData.latestDate || 'YESTERDAY'}`} />
                <CardCarousel
                    carouselItems={
                        [
                            {
                                label: "TVL",
                                data: dailyData.slice(-2).map(d => d.deposittokenbalanceusd),
                                valueFormat: currencyRounded,
                                about: "TVL (Total Value Locked) is the summed value in USD of all tokens deposited on Yield Yak farms. Currently it includes all single-asset farms that have their prices tracked on CoinGecko and most two-token liquidity pools. For more details on which farms are not currently included in this TVL figure, check out the farms page."
                            },
                            {
                                label: "Weighted APY",
                                data: dailyData.slice(-2).map(d => d.weightedapy),
                                valueFormat: percentageFormat,
                                about: "The sum product of APY and TVL across all farms with TVL > $10k, divided by summed TVL for those farms."
                            },
                            {
                                label: "Fees",
                                data: dailyData.slice(-2).map(d => d.rewardstoyieldyakusd),
                                valueFormat: currencyRounded,
                                about: "Summed USD value of tokens transferred to Yield Yak addresses (Yak Fee Collector primarily) as part of reinvest transactions."
                            },
                            {
                                label: "Net Deposits",
                                data: dailyData.slice(-2).map(d => d.netdepositamountusd),
                                valueFormat: currencyRounded,
                                about: "Deposits minus withdraws."
                            },
                            {
                                label: "Reinvests",
                                data: dailyData.slice(-2).map(d => d.numberofreinvests),
                                valueFormat: integerWithCommas,
                                about: "The number of reinvests that have taken place in the last 1 day."
                            },
                            {
                                label: "Reinvest margin",
                                data: dailyData.slice(-2).map(d => d.nondepositwithdrawreinvestrewardsaspercentageofgas - 1),
                                valueFormat: percentageFormat,
                                about: "The average profit margin on rewards paid to addresses which trigger reinvests (excluding any which happen automatically as part of a deposit/withdraw)."
                            },
                            {
                                label: "Net Growth (30 days)",
                                data: dailyData.slice(-2).map(d => d.deposittokenbalancenetgrowthpercentagelast30days),
                                valueFormat: percentageFormat,
                                about: "This value represents how much higher the combined TVL is over the last 30 days as a result of auto-compounding the reward tokens from farms"
                            },
                            {
                                label: "Effective Fees (30 days)",
                                data: dailyData.slice(-2).map(d => d.effectivefeespercentagelast30days),
                                valueFormat: percentageFormat,
                                about: "The fees taken by Yield Yak as a percentage of the Net Growth over the last 30 days."
                            },
                        ]
                    }
                />
                <CustomDivider label={"HISTORICAL DATA"} />
                <Grid container spacing={3}>
                    {
                        [
                            {
                                chartType: "line", title: "TVL", data: dailyData, yAxisLineData: "deposittokenbalanceusd",
                                xAxisData: "datetimestamp", valueFormat: currencyRounded, handleUpdateData: handleUpdateData
                            },
                            {
                                chartType: "line", title: "Weighted APY", data: dailyData, yAxisLineData: "weightedapy",
                                xAxisData: "datetimestamp", valueFormat: percentageFormat, handleUpdateData: handleUpdateData
                            },
                            {
                                chartType: "line", title: "Revenue / Fees", data: dailyData, yAxisLineData: "rewardstoyieldyakusd",
                                xAxisData: "datetimestamp", valueFormat: currencyRounded, handleUpdateData: handleUpdateData
                            },
                            {
                                chartType: "combo", title: "Deposits / Withdraws / Net Deposits", data: dailyData.map(
                                    ({ depositamountusd, withdrawamountusd, netdepositamountusd, ...others }) => (
                                        {
                                            ...others,
                                            "Deposits": depositamountusd,
                                            "Withdraws": -withdrawamountusd,
                                            "Net Deposits": netdepositamountusd
                                        }
                                    )), yAxisBarData1: "Deposits", yAxisBarData2: "Withdraws", yAxisLineData: "Net Deposits",
                                xAxisData: "datetimestamp", valueFormat: currencyRounded, handleUpdateData: handleUpdateData
                            },
                            {
                                chartType: "line", title: "# Reinvests", data: dailyData, yAxisLineData: "numberofreinvests",
                                xAxisData: "datetimestamp", valueFormat: integerWithCommas, handleUpdateData: handleUpdateData
                            },
                            {
                                chartType: "line", title: "Reinvest reward as % of gas", data: dailyData, yAxisLineData: "nondepositwithdrawreinvestrewardsaspercentageofgas",
                                xAxisData: "datetimestamp", valueFormat: percentageFormat, handleUpdateData: handleUpdateData
                            },
                            {
                                chartType: "line", title: "Net Growth % (30 days)", data: dailyData, yAxisLineData: "deposittokenbalancenetgrowthpercentagelast30days",
                                xAxisData: "datetimestamp", valueFormat: percentageFormat, handleUpdateData: handleUpdateData
                            },
                            {
                                chartType: "line", title: "Effective Fees % (30 days)", data: dailyData, yAxisLineData: "effectivefeespercentagelast30days",
                                xAxisData: "datetimestamp", valueFormat: percentageFormat, handleUpdateData: handleUpdateData
                            },
                        ].map(
                            chartInfo => (
                                <Grid item xs={12} md={6} key={chartInfo.title}>
                                    <ChartCardWithModal
                                        chartType={chartInfo.chartType}
                                        title={chartInfo.title}
                                        data={chartInfo.data}
                                        yAxisLineData={chartInfo.yAxisLineData}
                                        yAxisBarData1={chartInfo.yAxisBarData1}
                                        yAxisBarData2={chartInfo.yAxisBarData2}
                                        xAxisData={chartInfo.xAxisData}
                                        valueFormat={chartInfo.valueFormat}
                                        handleUpdateData={chartInfo.handleUpdateData}
                                    />
                                </Grid>
                            )
                        )
                    }
                </Grid>
            </Container>
        </Box>
    )
};