import { Divider, Typography } from "@mui/material";

export const CustomDivider = (props) => {
    const { label, customChild, sxProps = { mb: 3 } } = props;
    return (
        <Divider sx={{
            width: '100%',
            "&::before, &::after": {
                borderColor: "primary.main",
            },
            ...sxProps
        }}>
            {
                customChild ||
                <Typography variant='subtitle2'>
                    {label}
                </Typography>
            }
        </Divider>
    )
};