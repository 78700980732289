import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { AppBar, Avatar, Button, IconButton, ListItemAvatar, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppDataContext } from '../utils/app-data-context';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import avalancheLogo from '../assets/logos/avalanche.png';
import arbitrumLogo from '../assets/logos/arbitrum.png';
import { useTheme } from '@emotion/react';

const chainLogos = {
  avalanche: avalancheLogo,
  arbitrum: arbitrumLogo
};

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  //   backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, ...other } = props;
  const { chain, updateChain } = React.useContext(AppDataContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewChain = (newChain) => {
    handleClose();
    updateChain(newChain);
  };

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          },
          background: "linear-gradient(to right, #0eab4d, #121614)"
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Typography color="background.default" sx={{ ml: 1, fontWeight: 700, fontSize: { sm: 24, xs: 18 } }}>
            YIELD YAK ANALYTICS
          </Typography>
          <Button
            variant="contained"
            onClick={handleClick}
            startIcon={<Avatar src={chainLogos[chain]} sx={{ height: 25, width: 25 }} />}
            endIcon={<ExpandMoreIcon />}
            sx={{ minWidth: 150, backgroundColor: theme.palette.background.paper, ml: 5, px: 2, py: 1, ":hover": { backgroundColor: theme.palette.background.lightPaper } }}
          >
            {chain}
          </Button>
          <Menu
            id="chain-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {
              Object.keys(chainLogos).map(key => (
                <MenuItem
                  key={key}
                  onClick={() => handleNewChain(key)}
                >
                  <ListItemAvatar>
                    <Avatar src={chainLogos[key]} sx={{ height: 25, width: 25 }} />
                  </ListItemAvatar>
                  <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
                    {key.toUpperCase()}
                  </ListItemText>
                </MenuItem>
              ))
            }
          </Menu>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};