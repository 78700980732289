// This file supports the farms page by giving shortcuts for varying collections of columns to show as visible.

// All columns
const hideAllModel = {
    APY: false,
    adminFee: false,
    churnRate: false,
    daysSinceLastReinvest: false,
    depositAmount: false,
    depositAmountUSD: false,
    depositTokenBalance: false,
    depositTokenBalanceNetGrowthPercentageLast30Days: false,
    depositTokenBalanceUSD: false,
    depositTokenPrice: false,
    depositTokenPricesAvailable: false,
    depositTokenSymbol: false,
    depositingWallets: false,
    depositsEnabled: false,
    devFee: false,
    effectiveFeesPercentageLast30Days: false,
    farmAddress: false,
    farmCreatedDate: false,
    netDepositAmount: false,
    netDepositAmountUSD: false,
    nonDepositWithdrawReinvestRewardsAsPercentageOfGas: false,
    numberOf1KWallets: false,
    numberOfActiveWallets: false,
    numberOfChurningWallets: false,
    numberOfDeposits: false,
    numberOfNewActiveWallets: false,
    numberOfNewWallets: false,
    numberOfReactivatingWallets: false,
    numberOfReinvests: false,
    numberOfWhaleWallets: false,
    numberOfWithdraws: false,
    percentageTVLFromWhales: false,
    reactivationRate: false,
    reinvestReward: false,
    rewardTokenPrice: false,
    rewardTokenPricesAvailable: false,
    rewardTokenSymbol: false,
    rewardsToYieldYak: false,
    rewardsToYieldYakUSD: false,
    withdrawAmount: false,
    withdrawAmountUSD: false,
    withdrawingWallets: false,
};

export const columnModels = {
    "default": {
        ...hideAllModel,
        ...{
            APY: true,
            depositTokenBalanceNetGrowthPercentageLast30Days: true,
            depositTokenBalanceUSD: true,
            effectiveFeesPercentageLast30Days: true,
            netDepositAmountUSD: true,
            nonDepositWithdrawReinvestRewardsAsPercentageOfGas: true,
            numberOfActiveWallets: true,
            numberOfReinvests: true,
            rewardsToYieldYakUSD: true,
        }
    },
    "balance": {
        ...hideAllModel,
        ...{
            APY: true,
            depositTokenBalance: true,
            depositTokenBalanceNetGrowthPercentageLast30Days: true,
            depositTokenBalanceUSD: true,
            depositTokenPrice: true,
            depositTokenPricesAvailable: true,
            depositTokenSymbol: true,
            effectiveFeesPercentageLast30Days: true,
            percentageTVLFromWhales: true,
        }
    },
    "depsWiths": {
        ...hideAllModel,
        ...{
            depositAmount: true,
            depositAmountUSD: true,
            depositTokenPrice: true,
            depositTokenSymbol: true,
            depositingWallets: true,
            netDepositAmount: true,
            netDepositAmountUSD: true,
            numberOfDeposits: true,
            numberOfWithdraws: true,
            withdrawAmount: true,
            withdrawAmountUSD: true,
            withdrawingWallets: true,
        }
    },
    "reinvests": {
        ...hideAllModel,
        ...{
            APY: true,
            adminFee: true,
            daysSinceLastReinvest: true,
            devFee: true,
            nonDepositWithdrawReinvestRewardsAsPercentageOfGas: true,
            numberOfReinvests: true,
            reinvestReward: true,
            rewardTokenPrice: true,
            rewardTokenSymbol: true,
            rewardsToYieldYak: true,
            rewardsToYieldYakUSD: true,
        }
    },
    "farmInfo": {
        ...hideAllModel,
        ...{
            adminFee: true,
            daysSinceLastReinvest: true,
            depositTokenPrice: true,
            depositTokenPricesAvailable: true,
            depositTokenSymbol: true,
            depositsEnabled: true,
            devFee: true,
            farmAddress: true,
            farmCreatedDate: true,
            reinvestReward: true,
            rewardTokenPrice: true,
            rewardTokenPricesAvailable: true,
            rewardTokenSymbol: true,
        }
    },
    "users": {
        ...hideAllModel,
        ...{
            churnRate: true,
            depositingWallets: true,
            numberOf1KWallets: true,
            numberOfActiveWallets: true,
            numberOfChurningWallets: true,
            numberOfNewActiveWallets: true,
            numberOfNewWallets: true,
            numberOfReactivatingWallets: true,
            numberOfWhaleWallets: true,
            percentageTVLFromWhales: true,
            reactivationRate: true,
            withdrawingWallets: true,
        }
    },
};

export const swapTokenColumnsModel = {
    token: false,
    yakSwapVolumeUSD: true,
    amountInUSD: false,
    amountOutUSD: false,
    combinedSwapsFromAndToToken: true,
    swapsFromToken: false,
    swapsToToken: false,
    uniqueTradersSwappingFrom: true,
    uniqueTradersSwappingTo: true,
    amountInAndOut: false,
    amountIn: true,
    amountOut: true,
};

export const yakOwnershipColumnsModel = {
    yakBalanceChange1Day: false,
    yakBalanceChange7Days: false,
    yakBalanceChange30Days: false,
    yakBalanceChange90Days: false,
    previousBalance: true,
    newBalance: true,
};

export const feesColumnsModel = {
    farmAddress: false,
    depositTokenBalanceUSD: true,
    APY: true,
    adminFee: false,
    devFee: false,
    reinvestReward: false,
    reinvestFrequency: true,
    gasCostUSDPerReinvest: true,
    APR: true,
    APYMax: false,
    APYPersonal: true,
    benefitFactor: true,
};