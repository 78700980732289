import { currencyRounded, integerWithCommas, percentageFormat, scientificFormat } from "../utils/number-formats";

export const farmFields = [
    { field: 'farmAddress', name: 'Farm address', type: 'string' },
    { field: 'farmCreatedDate', name: 'Farm created date', type: 'date' },
    {
        field: 'depositTokenSymbol', name: 'Deposit token symbol', type: 'string', width: 170,
        valueGetter: (params) => {
            const { token0symbol, token1symbol } = params.row.depositTokenInfo;
            return token0symbol ? `${params.value} (${token0symbol} & ${token1symbol})` : params.value
        }
    },
    { field: 'depositsEnabled', name: 'Deposits enabled', type: 'boolean' },
    { field: 'depositTokenPricesAvailable', name: 'Has deposit token prices', type: 'boolean' },
    { field: 'depositTokenPrice', name: 'Deposit token price', valueFormat: currencyRounded, hideBasedOnDepositTokenPrice: true, hideBasedOnDepositToken: true },
    { field: 'rewardTokenSymbol', name: 'Reward token symbol', type: 'string' },
    { field: 'rewardTokenPricesAvailable', name: 'Has reward token prices', type: 'boolean' },
    { field: 'rewardTokenPrice', name: 'Reward token price', valueFormat: currencyRounded, hideBasedOnRewardTokenPrice: true, hideBasedOnRewardToken: true },
    { field: 'daysSinceLastReinvest', name: '# Days since reinvest', valueFormat: integerWithCommas, skip24HChange: true, skipChart: true },
    { field: 'adminFee', name: 'Admin Fee', valueFormat: percentageFormat, skip24HChange: true },
    { field: 'devFee', name: 'Dev Fee', valueFormat: percentageFormat, skip24HChange: true },
    { field: 'reinvestReward', name: 'Reinvest Reward', valueFormat: percentageFormat, skip24HChange: true },
    { field: 'depositTokenBalance', name: 'Deposit token balance', valueFormat: scientificFormat, hideBasedOnDepositToken: true },
    { field: 'depositTokenBalanceUSD', name: 'TVL', valueFormat: currencyRounded, hideBasedOnDepositTokenPrice: true },
    { field: 'APY', name: 'APY', valueFormat: percentageFormat, hideBasedOnDepositTokenPriceForMultiple: true },
    { field: 'numberOfActiveWallets', name: '# Active Wallets (> $1)', valueFormat: integerWithCommas, hideBasedOnDepositTokenPrice: true },
    { field: 'numberOfDeposits', name: '# Deposits', valueFormat: integerWithCommas },
    { field: 'depositingWallets', name: '# Depositing Wallets', valueFormat: integerWithCommas },
    { field: 'depositAmount', name: 'Deposits (token)', valueFormat: scientificFormat, hideBasedOnDepositToken: true },
    { field: 'depositAmountUSD', name: 'Deposits (USD)', valueFormat: currencyRounded, hideBasedOnDepositTokenPrice: true },
    { field: 'numberOfWithdraws', name: '# Withdraws', valueFormat: integerWithCommas },
    { field: 'withdrawingWallets', name: '# Withdrawing Wallets', valueFormat: integerWithCommas },
    { field: 'withdrawAmount', name: 'Withdraws (token)', valueFormat: scientificFormat, hideBasedOnDepositToken: true },
    { field: 'withdrawAmountUSD', name: 'Withdraws (USD)', valueFormat: currencyRounded, hideBasedOnDepositTokenPrice: true },
    { field: 'netDepositAmount', name: 'Net deposits (token)', valueFormat: scientificFormat, hideBasedOnDepositToken: true, chartType: 'combo', yAxisBarData1: { field: "depositAmount", name: "Deposits" }, yAxisBarData2: { field: "withdrawAmount", name: "Withdraws" } },
    { field: 'netDepositAmountUSD', name: 'Net deposits (USD)', valueFormat: currencyRounded, hideBasedOnDepositTokenPrice: true, chartType: 'combo', yAxisBarData1: { field: "depositAmountUSD", name: "Deposits (USD)" }, yAxisBarData2: { field: "withdrawAmountUSD", name: "Withdraws (USD)" } },
    { field: 'numberOfReinvests', name: '# Reinvests', valueFormat: integerWithCommas },
    { field: 'rewardsToYieldYak', name: 'Revenue (token)', valueFormat: scientificFormat, hideBasedOnRewardToken: true },
    { field: 'rewardsToYieldYakUSD', name: 'Revenue (USD)', valueFormat: currencyRounded, hideBasedOnRewardTokenPrice: true },
    { field: 'nonDepositWithdrawReinvestRewardsAsPercentageOfGas', name: 'Reinvest rewards as % of gas', valueFormat: percentageFormat, hideBasedOnRewardTokenPrice: true },
    { field: 'effectiveFeesPercentageLast30Days', name: 'Effective fees (30 days)', valueFormat: percentageFormat, hideBasedOnDepositTokenPrice: true, hideBasedOnRewardTokenPrice: true },
    { field: 'depositTokenBalanceNetGrowthPercentageLast30Days', name: 'Net growth (30 days)', valueFormat: percentageFormat, hideBasedOnDepositTokenPriceForMultiple: true },
    { field: 'numberOfNewWallets', name: '# New Wallets', valueFormat: integerWithCommas },
    { field: 'numberOfNewActiveWallets', name: '# New Active Wallets (> $1)', valueFormat: integerWithCommas, hideBasedOnDepositTokenPrice: true },
    { field: 'numberOf1KWallets', name: '# $1K Wallets', valueFormat: integerWithCommas, hideBasedOnDepositTokenPrice: true },
    { field: 'numberOfWhaleWallets', name: '# Whale Wallets (> $1M)', valueFormat: integerWithCommas, hideBasedOnDepositTokenPrice: true },
    { field: 'percentageTVLFromWhales', name: '% TVL from Whales', valueFormat: percentageFormat, hideBasedOnDepositTokenPrice: true },
    { field: 'numberOfChurningWallets', name: '# Churning Wallets', valueFormat: integerWithCommas, hideBasedOnDepositTokenPrice: true },
    { field: 'churnRate', name: 'Churn rate', valueFormat: percentageFormat, hideBasedOnDepositTokenPrice: true },
    { field: 'numberOfReactivatingWallets', name: '# Reactivating Wallets', valueFormat: integerWithCommas, hideBasedOnDepositTokenPrice: true },
    { field: 'reactivationRate', name: 'Reactivation rate', valueFormat: percentageFormat, hideBasedOnDepositTokenPrice: true },
];

export const swapTokenFields = [
    { field: 'token', name: 'Token Address', type: 'string' },
    { field: 'yakSwapVolumeUSD', name: 'Yak Swap Volume (USD)', valueFormat: currencyRounded },
    { field: 'amountInUSD', name: 'Amount Swapped From Token (USD)', valueFormat: currencyRounded },
    { field: 'amountOutUSD', name: 'Amount Swapped To Token (USD)', valueFormat: currencyRounded },
    { field: 'combinedSwapsFromAndToToken', name: '# Swaps From/To Token', valueFormat: integerWithCommas },
    { field: 'swapsFromToken', name: '# Swaps From Token', valueFormat: integerWithCommas },
    { field: 'swapsToToken', name: '# Swaps To Token', valueFormat: integerWithCommas },
    { field: 'uniqueTradersSwappingFrom', name: 'Unique Traders Swapping From Token', valueFormat: integerWithCommas },
    { field: 'uniqueTradersSwappingTo', name: 'Unique Traders Swapping To Token', valueFormat: integerWithCommas },
    { field: 'amountInAndOut', name: 'Amount Swapped To And From Token', valueFormat: scientificFormat },
    { field: 'amountIn', name: 'Amount Swapped From Token', valueFormat: scientificFormat },
    { field: 'amountOut', name: 'Amount Swapped To Token', valueFormat: scientificFormat },
];

export const feesFields = [
    { field: 'farmAddress', name: 'Farm Address', type: 'string' },
    { field: 'depositTokenBalanceUSD', name: 'TVL', valueFormat: currencyRounded },
    { field: 'APY', name: 'APY', valueFormat: percentageFormat },
    { field: 'adminFee', name: 'Admin Fee', valueFormat: percentageFormat },
    { field: 'devFee', name: 'Dev Fee', valueFormat: percentageFormat },
    { field: 'reinvestReward', name: 'Reinvest Reward', valueFormat: percentageFormat },
    { field: 'reinvestFrequency', name: '# Reinvests / year *', valueFormat: scientificFormat, width: 140 },
    { field: 'gasCostUSDPerReinvest', name: 'Gas per reinvest (USD) *', valueFormat: currencyRounded },
    { field: 'APR', name: 'Base APR *', valueFormat: percentageFormat },
    { field: 'APYMax', name: 'Max APY *', valueFormat: percentageFormat },
    { field: 'APYPersonal', name: 'Personal APY', valueFormat: percentageFormat },
];