import { Box, Card, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InfoIcon from '@mui/icons-material/Info';

export const SummaryInfoCard = (props) => {
    const { label, data, valueFormat, about } = props;
    const changeLast1Day = data.length > 1 ? (data[1] - data[0]) / Math.abs(data[0]) : 0;
    return (
        <Card sx={{ height: '100%', background: "linear-gradient(#1e2220, #121614)", border: 1, borderColor: 'rgb(100,100,100)' }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                    <Grid item xs={10}>
                        <Typography color="primary" sx={{ fontWeight: 600, fontSize: { xs: 16, sm: 18, md: 20 } }}>
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} container justifyContent="flex-end">
                        <Tooltip title={about}>
                            <InfoIcon />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ fontWeight: 400, fontSize: { xs: 30, sm: 32, md: 34 } }}>
                            {data.length > 0 ? valueFormat(data.slice(-1)[0]) : '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                            {changeLast1Day >= 0 ? <ArrowUpwardIcon color="success" /> : <ArrowDownwardIcon color="error" />}
                            <Typography variant="body1" color={changeLast1Day >= 0 ? "success.main" : "error"} sx={{ mr: 1 }}>
                                {`${Math.round(100 * Math.abs(changeLast1Day))}%`}
                            </Typography>
                            <Typography variant="body1">
                                24 hrs
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}