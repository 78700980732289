import { Box, Button, Checkbox, Chip, Container, Divider, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Paper, Skeleton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import * as React from 'react';
import { Storage } from 'aws-amplify';
import { useTheme } from '@emotion/react';
import { currencyRounded } from '../utils/number-formats';
import { UserPortfolioCard } from '../components/user-portfolio-card';
import { ChartCardWithModal } from '../components/chart-card-with-modal';
import { PortfolioFarmAccordion } from '../components/portfolio-farm-accordion';
import { CustomDivider } from '../components/custom-divider';
import { AppDataContext } from '../utils/app-data-context';

export default function Portfolio() {
    const { chain } = React.useContext(AppDataContext);
    const [address, setAddress] = React.useState("");
    const [addressError, setAddressError] = React.useState(false);
    const [chosenAddresses, setChosenAddresses] = React.useState([]);
    const [latestFarmData, setLatestFarmData] = React.useState([]);
    const [userData, setUserData] = React.useState([]);
    const [userDataAgg, setUserDataAgg] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [hideOldFarms, setHideOldFarms] = React.useState(true);

    React.useEffect(() => {
        setUserData([]);
        setUserDataAgg([]);
        loadLatestFarmData();
    }, [chain]);

    const loadLatestFarmData = async () => {
        setLatestFarmData([]);
        const latestStorageInfo = await Storage.get(`${chain}/agg-data/latestFarmData.json`, { download: true });
        const newLatestFarmData = await new Response(latestStorageInfo.Body).json();
        setLatestFarmData(newLatestFarmData);
    };

    const loadPortfolioData = async () => {
        if (chosenAddresses.length > 0) {
            setLoading(true);
            setUserData([]);
            setUserDataAgg([]);
            // First thing we do is check whether a file already exists in storage for the chosen addresses.
            let matchingFiles = await Storage.list(`${chain}/daily-user-data/user-query-results/${chosenAddresses.join("")}/`);
            matchingFiles = matchingFiles.filter(f => f.key.endsWith(".csv"));
            let userDataPath;
            if (matchingFiles.length > 0) {
                // This means we have something which matches and therefore do not need to run the query again.
                userDataPath = matchingFiles[0].key;
            } else {
                const response = await fetch(process.env.REACT_APP_YY_USER_DATA_AWS_LAMBDA_URL, { method: 'POST', body: JSON.stringify({ addresses: chosenAddresses, chain }) });
                // const response = await fetch(process.env.REACT_APP_YY_AWS_LAMBDA_URL, { method: 'POST', body: JSON.stringify({ addresses: chosenAddresses }) });
                let info = await response.json();
                userDataPath = info.result;
            };
            // Now that we have the path for our user data, we retrieve it from storage.
            // Sometimes the query will not have finished before we try to retrieve the data, in which case we want to wait a little before trying again.
            let keepTrying = true;
            let userDataText;
            let numAttempts = 0;
            while (keepTrying && numAttempts < 5) {
                try {
                    const userDataStorageInfo = await Storage.get(userDataPath, { download: true });
                    userDataText = await userDataStorageInfo.Body.text();
                    keepTrying = false;
                } catch (e) {
                    console.log(e);
                    console.log("Trying again...");
                    const delay = (ms = 3000) => new Promise(r => setTimeout(r, ms));
                    await delay();
                    numAttempts += 1;
                }
            };
            // We do this double quote then single quote replacement stuff to handle the JSONs in depositTokenMetrics
            userDataText = userDataText.replaceAll('""', "'").replaceAll('"', '').replaceAll("'", '"');
            const lines = userDataText.split("\n");
            const header = lines[0].split(",");
            let newUserData = lines.slice(1, -1).map( // Using this slice because first value is the header, and last is an empty row.
                line => {
                    const values = line.split(/(?<=[\d\}\w\,]),(?=[\d\,\{\-])/); // This fancier split takes care of depositTokenMetrics for us correctly, along with the correction below
                    const newObj = {};
                    header.forEach((key, i) => { newObj[key] = ["farmAddress", "date"].includes(key) ? values[i] : (key === "depositTokenMetrics" ? JSON.parse(values[i] || '{}') : Number(values[i])) });
                    return newObj;
                }
            );
            // At this point we have got the detailed newUserData into one variable, and we have the latestFarmData also.
            // Now just make a couple of additional metrics that are useful for the TWRR calculation later.
            // We also add the amount earned from swap fees in the last 1 day here too. Note that for this we base the calculation
            // on what the balance for that day started as, rather than what it ended as. It's built into our approach that we assume
            // the cashflow happens at the end of the day, so it makes sense for the swap fees to be proportional to the value at the
            // start of the day.
            newUserData = newUserData.map(row => (
                {
                    ...row,
                    numberOfDepositsOrWithdraws: row.numberOfDepositsAndTransfersIn + row.numberOfWithdrawsAndTransfersOut,
                    netDepositAmount: row.depositsAndTransfersIn - row.withdrawsAndTransfersOut,
                    netDepositAmountUSD: row.depositsAndTransfersInUSD - row.withdrawsAndTransfersOutUSD,
                    swapFeesEarnedLast1Day: (1 - 1 / (row.depositTokenMetrics.lptokengrowthratio1day || 1)) * (row.userDepositTokenBalance - (row.depositsAndTransfersIn - row.withdrawsAndTransfersOut)),
                }
            ));
            // We need to make the relevant adjustments to take swap/trading fees into account BEFORE we aggregate the data.
            // In order to do so, we need to loop over the array, going row by row. VERY IMPORTANT that it's in order already, which it should be.
            let previousFarm;
            let currentFarm;
            let cumulativeSwapFeesEarned;
            let cumulativeSwapFeesEarnedUSD;
            let cumulativeSwapFeesGrowthRatio;
            for (let i = 0; i < newUserData.length; i++) {
                // What is our current farm address?
                currentFarm = newUserData[i].farmAddress;
                if (currentFarm !== previousFarm) {
                    // In this case, we've moved on so need to start counting from 0 again.
                    cumulativeSwapFeesEarned = 0;
                    cumulativeSwapFeesEarnedUSD = 0;
                    cumulativeSwapFeesGrowthRatio = 1;
                    // And we set the previous farm equal to the current.
                    previousFarm = currentFarm;
                }
                // The line below is the iterative formula where the current cumulative swap fees earned is a constant plus a mutiple of the previous cumulative value (hard/impossible to do in SQL).
                cumulativeSwapFeesEarned = newUserData[i].swapFeesEarnedLast1Day + cumulativeSwapFeesEarned / (newUserData[i].depositTokenMetrics.lptokengrowthratio1day || 1);
                // Now just convert to a $ value.
                cumulativeSwapFeesEarnedUSD = cumulativeSwapFeesEarned * newUserData[i].depositTokenPrice;
                // And here we adjust the net deposits to date values.
                newUserData[i].cumulativeNetDepositsAdjusted = newUserData[i].cumulativeNetDeposits - cumulativeSwapFeesEarned;
                newUserData[i].cumulativeNetDepositsUSDAdjusted = newUserData[i].cumulativeNetDepositsUSDPresentValue - cumulativeSwapFeesEarnedUSD;
                // The cumulative swap fees growth ratio is used in the TWRR calculation later on.
                cumulativeSwapFeesGrowthRatio *= (newUserData[i].depositTokenMetrics.lptokengrowthratio1day || 1);
                newUserData[i].cumulativeSwapFeesGrowthRatio = cumulativeSwapFeesGrowthRatio;
            };
            // Before working on the farm-level data (tweaks to newUserData), we will create the aggregated user data.
            // This means summing and grouping by here itself (rather than running another query).
            let newUserDataAgg = [];
            newUserData.reduce(
                (res, row) => {
                    if (!res[row.dateTimestamp]) {
                        res[row.dateTimestamp] = ({
                            dateTimestamp: row.dateTimestamp,
                            date: row.date,
                            userDepositTokenBalanceUSD: 0,
                            numberOfDeposits: 0,
                            numberOfDepositsAndTransfersIn: 0,
                            depositAmountUSD: 0,
                            depositsAndTransfersInUSD: 0,
                            numberOfWithdraws: 0,
                            numberOfWithdrawsAndTransfersOut: 0,
                            withdrawAmountUSD: 0,
                            withdrawsAndTransfersOutUSD: 0,
                            cumulativeNetDepositsUSDPresentValue: 0,
                            cumulativeNetDepositsUSDAdjusted: 0,
                            cumulativeNetDepositsUSD: 0,
                            numberOfDepositsOrWithdraws: 0,
                            netDepositAmountUSD: 0,
                            farms: []
                        });
                        newUserDataAgg.push(res[row.dateTimestamp]);
                    };
                    res[row.dateTimestamp].userDepositTokenBalanceUSD += row.userDepositTokenBalanceUSD;
                    res[row.dateTimestamp].numberOfDeposits += row.numberOfDeposits;
                    res[row.dateTimestamp].numberOfDepositsAndTransfersIn += row.numberOfDepositsAndTransfersIn;
                    res[row.dateTimestamp].depositAmountUSD += row.depositAmountUSD;
                    res[row.dateTimestamp].depositsAndTransfersInUSD += row.depositsAndTransfersInUSD;
                    res[row.dateTimestamp].numberOfWithdraws += row.numberOfWithdraws;
                    res[row.dateTimestamp].numberOfWithdrawsAndTransfersOut += row.numberOfWithdrawsAndTransfersOut;
                    res[row.dateTimestamp].withdrawAmountUSD += row.withdrawAmountUSD;
                    res[row.dateTimestamp].withdrawsAndTransfersOutUSD += row.withdrawsAndTransfersOutUSD;
                    res[row.dateTimestamp].cumulativeNetDepositsUSDPresentValue += row.cumulativeNetDepositsUSDPresentValue;
                    res[row.dateTimestamp].cumulativeNetDepositsUSDAdjusted += row.cumulativeNetDepositsUSDAdjusted;
                    res[row.dateTimestamp].cumulativeNetDepositsUSD += row.cumulativeNetDepositsUSD;
                    res[row.dateTimestamp].numberOfDepositsOrWithdraws += row.numberOfDepositsOrWithdraws;
                    res[row.dateTimestamp].netDepositAmountUSD += row.netDepositAmountUSD;
                    res[row.dateTimestamp].farms = res[row.dateTimestamp].farms.concat(row);
                    return res;
                }
                , {}
            );
            // Finally we sort the array.
            newUserDataAgg.sort((a, b) => a.dateTimestamp - b.dateTimestamp);
            // The final step here is the calculation of the simple return and time-weighted rate of return (TWRR) values.
            // Simple return is fairly simple, TWRR is more complex and depends on if/when there has been cashflow.
            // The reason we set them up as functions is because they'll be used again for the farm-level data.
            // And the reason we give variables is because for the overall level we have to use USD values, but at the farm
            // level we can use the token values (which means it works also for farms that we don't have prices for).
            // Simple Return function
            const calculateSimpleReturn = (dataArray, balanceVariable, contributionVariable) => {
                const newDataArray = dataArray.map(obj => (
                    {
                        ...obj,
                        simpleReturn: obj[contributionVariable] > 0 ? (obj[balanceVariable] / obj[contributionVariable]) - 1 : NaN
                    }
                ));
                return newDataArray;
            };
            // TWRR function (requires the array to be sorted already).
            const calculateTWRR = (dataArray, balanceVariable, cashflowCheckVariable, cashflowVariable, usingUSDValues = false) => {
                let newDataArray = dataArray.concat([]); // Makes a copy
                // Initially, key values are set according to day 0 values.
                let previousPeriodsTWRRFactor = 1;
                let startPeriodBalance = 0;
                let startPeriodFarms = [];
                let startPeriodCumSwapFeesRatio = 1;
                let endPeriodBalance;
                let endPeriodCumSwapFeesRatio;
                let adjustedStartPeriodBalance;
                // We treat the end of the day as the instance at which the deposits/inflows are made, so the TWRR value for day 0 will be zero.
                // Our loop starts on day 0.
                for (let i = 0; i < newDataArray.length; i++) {
                    // First thing to check is if there has been any cashflow activity.
                    // If there have been deposits and withdraws but net deposits are still zero, we still need to consider this as cashflow in/out as it could be from one farm to another.
                    if (newDataArray[i][cashflowCheckVariable] > 0) {
                        // We consider the end value as the balance BEFORE the net cashflow for this day has taken place, so have to subtract the net flow for this day.
                        // This ensures that we still get values even when inflows/outflows are happening every day (otherwise wouldn't work)
                        endPeriodBalance = newDataArray[i][balanceVariable] - newDataArray[i][cashflowVariable];
                        // We also add a line to keep track of the swap fees growth ratio, though this will only be used for the farm/non-USD calculations.
                        endPeriodCumSwapFeesRatio = newDataArray[i].cumulativeSwapFeesGrowthRatio;
                        // For the end of preiod farm-level values, we don't have to worry about adjustments as we're only using the price.
                        let endPeriodFarms = newDataArray[i].farms;
                        // Next we adjust the factor for previous TWRR periods.
                        // But first, some explanation:
                        //  - The line in the if condition below gives us an adjusted starting value that takes into account price movements.
                        //  - There is also part of the calculation where we multiply by the start swap fee ratio and divide by the end one.
                        //  - The reason for this is that 1 LP token at the start is not the same as 1 LP token at the end, even if the underlying
                        //    tokens don't change in price, because the fees from trades/swaps are added directly into the pool.
                        //  - This has the effect of "normalising" the token balance to account for additions into the pool from swap fees.
                        //  - In cases where this is not relevant (e.g. single token deposit farms), both values are 1 so it doesn't make a difference.
                        //  - This means the growth calculated after this adjustment has been made is purely from Yield Yak.
                        //  - And THIS is what we multiply by the previous factor in order to get our new TWRR factor.
                        //  - The use of adjustedPeriodBalance in this section is not strictly needed. Could have just changed startPeriodBalance.
                        //  - But it helps consistency with the next section, where it IS needed.
                        if (usingUSDValues) {
                            adjustedStartPeriodBalance = startPeriodFarms.reduce((res, row) => res + row[balanceVariable] * (endPeriodFarms.find(f => f.farmAddress === row.farmAddress).depositTokenPrice / row.depositTokenPrice) * (row.cumulativeSwapFeesGrowthRatio / endPeriodFarms.find(f => f.farmAddress === row.farmAddress).cumulativeSwapFeesGrowthRatio), 0);
                        } else {
                            // In this case we only make the adjustment based on the swap fee ratios, not price.
                            // And only applies when doing the farm-level analysis, so don't have an array of farms to use.
                            adjustedStartPeriodBalance = startPeriodBalance * startPeriodCumSwapFeesRatio / endPeriodCumSwapFeesRatio;
                        };
                        previousPeriodsTWRRFactor = previousPeriodsTWRRFactor * (isFinite(endPeriodBalance / adjustedStartPeriodBalance) ? (endPeriodBalance / adjustedStartPeriodBalance) : 1);
                        // The next step is assigning new values for our startPeriod variables.
                        startPeriodBalance = newDataArray[i][balanceVariable];
                        startPeriodFarms = endPeriodFarms;
                        startPeriodCumSwapFeesRatio = endPeriodCumSwapFeesRatio;
                    };
                    // Finally we can give the calculation for TWRR at this point in time.
                    // Some of the calculation is very similar to the previousPeriodsTWRRFactor adjustment in the section above,
                    // and that part will equal 1 on days when there is cashflow (so does not impact TWRR calculation; that day's
                    // impact instead comes from the adjusted previousPeriodsTWRRFactor).
                    // We NEED to use the adjustedStartPeriodBalance so that we do not incorrectly change startPeriodBalance, the
                    // value of which should ONLY change when there is some cashflow in/out.
                    if (usingUSDValues) {
                        adjustedStartPeriodBalance = startPeriodFarms.reduce((res, row) => res + row[balanceVariable] * (newDataArray[i].farms.find(f => f.farmAddress === row.farmAddress).depositTokenPrice / row.depositTokenPrice) * (row.cumulativeSwapFeesGrowthRatio / newDataArray[i].farms.find(f => f.farmAddress === row.farmAddress).cumulativeSwapFeesGrowthRatio), 0);
                    } else {
                        adjustedStartPeriodBalance = startPeriodBalance * startPeriodCumSwapFeesRatio / newDataArray[i].cumulativeSwapFeesGrowthRatio;
                    }
                    newDataArray[i].timeWeightedRateOfReturn = previousPeriodsTWRRFactor * (isFinite(newDataArray[i][balanceVariable] / adjustedStartPeriodBalance) ? (newDataArray[i][balanceVariable] / adjustedStartPeriodBalance) : 1) - 1;
                };
                return newDataArray;
            };
            // Now we carry out the calculations
            newUserDataAgg = calculateSimpleReturn(newUserDataAgg, "userDepositTokenBalanceUSD", "cumulativeNetDepositsUSDAdjusted");
            newUserDataAgg = calculateTWRR(newUserDataAgg, "userDepositTokenBalanceUSD", "numberOfDepositsOrWithdraws", "netDepositAmountUSD", true);
            // Get rid of the farms variable.
            newUserDataAgg = newUserDataAgg.map(({ farms, ...others }) => ({ ...others }));
            setUserDataAgg(newUserDataAgg);
            // Now that we have finished working on the aggregated data, we can get back to the farm-level data.
            // First thing we need to do is split the newUserData by farm. Can make use of the fact that they are in farmAddress order.
            let newUserFarmData = [];
            newUserData.reduce(
                (res, row) => {
                    if (!res[row.farmAddress]) {
                        res[row.farmAddress] = {
                            farmAddress: row.farmAddress,
                            ...latestFarmData.filter(
                                ({ farmAddress }) => farmAddress === row.farmAddress
                            ).map(
                                ({ farmName, depositTokenAddress, depositTokenSymbol, depositTokenInfo, depositTokenType }) => {
                                    const { token0symbol, token1symbol } = JSON.parse(depositTokenInfo || '{}');
                                    return (
                                        {
                                            farmName,
                                            depositTokenAddress,
                                            depositTokenSymbol,
                                            depositTokenLabel: token0symbol ? `${depositTokenSymbol} (${token0symbol} & ${token1symbol})` : depositTokenSymbol,
                                            depositTokenInfo: JSON.parse(depositTokenInfo || '{}'),
                                            depositTokenType
                                        }
                                    );
                                }
                            )[0],
                            timeSeriesData: []
                        };
                        newUserFarmData.push(res[row.farmAddress]);
                    };
                    res[row.farmAddress].timeSeriesData = res[row.farmAddress].timeSeriesData.concat(row);
                    return res;
                },
                {}
            );
            // Now we do the calculations to get Simple Return and TWRR (have done one acting on the other to save some space).
            newUserFarmData = newUserFarmData.map(
                ({ timeSeriesData, ...others }) => (
                    {
                        ...others,
                        timeSeriesData: calculateTWRR(
                            calculateSimpleReturn(timeSeriesData, "userDepositTokenBalance", "cumulativeNetDepositsAdjusted"),
                            "userDepositTokenBalance",
                            "numberOfDepositsOrWithdraws",
                            "netDepositAmount"
                        )
                    }
                )
            );
            // Final thing we do here is an adjustment for the LP tokens to add in some extra info on the token0 and token1 balances.
            newUserFarmData = newUserFarmData.map(
                ({ depositTokenType, timeSeriesData, ...others }) => {
                    let newTimeSeriesData;
                    // Only make the small changes if it's a standardLP token.
                    if (depositTokenType === 'standardLP') {
                        let token0NetDeposits = 0;
                        let token1NetDeposits = 0;
                        newTimeSeriesData = timeSeriesData.map(
                            ({ farmDepositTokenBalance, netDepositAmount, userDepositTokenBalance, cumulativeNetDepositsAdjusted, depositTokenMetrics, ...timeSeriesOthers }) => {
                                token0NetDeposits += netDepositAmount * depositTokenMetrics.token0shares;
                                token1NetDeposits += netDepositAmount * depositTokenMetrics.token1shares;
                                return (
                                    {
                                        ...timeSeriesOthers,
                                        farmDepositTokenBalance,
                                        netDepositAmount,
                                        userDepositTokenBalance,
                                        cumulativeNetDepositsAdjusted,
                                        depositTokenMetrics,
                                        farmDepositTokenBalanceToken0: farmDepositTokenBalance * depositTokenMetrics.token0shares,
                                        farmDepositTokenBalanceToken1: farmDepositTokenBalance * depositTokenMetrics.token1shares,
                                        netDepositAmountToken0: netDepositAmount * depositTokenMetrics.token0shares,
                                        netDepositAmountToken1: netDepositAmount * depositTokenMetrics.token1shares,
                                        cumulativeNetDepositsToken0: token0NetDeposits,
                                        cumulativeNetDepositsToken1: token1NetDeposits,
                                        cumulativeNetDepositsToken0Adjusted: cumulativeNetDepositsAdjusted * depositTokenMetrics.token0shares,
                                        cumulativeNetDepositsToken1Adjusted: cumulativeNetDepositsAdjusted * depositTokenMetrics.token1shares,
                                        userDepositTokenBalanceToken0: userDepositTokenBalance * depositTokenMetrics.token0shares,
                                        userDepositTokenBalanceToken1: userDepositTokenBalance * depositTokenMetrics.token1shares,
                                    }
                                );
                            }
                        );
                    } else {
                        newTimeSeriesData = timeSeriesData;
                    };
                    return (
                        {
                            ...others,
                            depositTokenType,
                            timeSeriesData: newTimeSeriesData
                        }
                    );
                }
            );
            // Now we add the most recent data to the top level for easy access.
            newUserFarmData = newUserFarmData.map(
                farm => ({ ...farm, ...farm.timeSeriesData.slice(-1)[0] })
            );
            // Final thing to do is sort the array by the user's value descending.
            newUserFarmData.sort((a, b) => b.userDepositTokenBalanceUSD === a.userDepositTokenBalanceUSD ? b.userDepositTokenBalance - a.userDepositTokenBalance : b.userDepositTokenBalanceUSD - a.userDepositTokenBalanceUSD)
            // And we're done, so update the states.
            setUserData(newUserFarmData);
            setLoading(false);
        }
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value.slice(0, 42).toLowerCase());
        setAddressError(false);
    };

    const handleAddAddress = () => {
        if (chosenAddresses.includes(address)) {
            // do nothing
            return;
        }
        if (address.match(/^0x[a-f0-9]{40}/)) {
            let newChosenAddresses = chosenAddresses.concat([address]);
            // Keep them alphabetized.
            newChosenAddresses.sort();
            setChosenAddresses(newChosenAddresses);
            setAddress("");
        } else {
            setAddressError(true);
        }
    };

    const handleRemoveAddress = (removeAddress) => {
        const newChosenAddresses = chosenAddresses.filter(cAdd => cAdd !== removeAddress);
        setChosenAddresses(newChosenAddresses);
    };

    const handleUpdateAggData = () => {
        // This line is a hacky way to force the chart to re-render (otherwise the brush doesn't update).
        // We need the .concat([]), else it doesn't see a change so doesn't re-render.
        setUserDataAgg(userDataAgg.concat([]));
    };

    const handleUpdateData = (index) => {
        // This line is a hacky way to force the chart to re-render (otherwise the brush doesn't update).
        // We need the .concat([]), else it doesn't see a change so doesn't re-render.
        const newTimeSeriesData = userData[index].timeSeriesData.concat([]);
        const newUserData = userData.concat([]);
        newUserData[index].timeSeriesData = newTimeSeriesData;
        setUserData(newUserData);
    };

    const theme = useTheme();

    return (
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
            <Container maxWidth={false}>
                <Grid container spacing={3} sx={{ mb: 5 }} alignItems="center">
                    <Grid item xs={12} container>
                        <TextField
                            focused
                            label="Address(es)"
                            placeholder='0x0000000000000000000000000000000000000000'
                            FormHelperTextProps={{ style: { color: theme.palette.text.primary } }}
                            sx={{ width: '52ch' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleAddAddress} disabled={chosenAddresses.length >= 5} sx={{ color: 'primary.main' }}>
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            value={address}
                            onChange={handleAddressChange}
                            error={addressError}
                            helperText={
                                <>
                                    <Typography variant="caption">
                                        {addressError ? "Invalid address" : "Add up to 5 addresses"}
                                    </Typography>
                                    <Tooltip title={
                                        <Stack spacing={2}>
                                            <Typography variant="subtitle2">
                                                Why add more than one address?
                                            </Typography>
                                            <Typography variant="tooltip">
                                                In some cases, users have their assets spread over multiple wallets.
                                                Sometimes they may directly transfer the YRT tokens between wallets, rather than withdrawing and re-depositing.
                                            </Typography>
                                            <Typography variant="tooltip">
                                                You can enter more than one address if you would like to see your performance as a whole.
                                            </Typography>
                                        </Stack>

                                    }
                                    >
                                        <InfoIcon sx={{ fontSize: 16 }} />
                                    </Tooltip>
                                </>
                            }
                        />
                        <Paper border={0} sx={{ height: 80, width: 'max(45ch, calc(100% - 52ch - 40px))', overflow: "auto", padding: 1, ml: 2 }}>
                            {chosenAddresses.map(
                                cAddress => (
                                    <Chip key={cAddress} variant="outlined" color="info" label={cAddress} onDelete={() => handleRemoveAddress(cAddress)} />
                                )
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                        <CustomDivider
                            sxProps={{}}
                            customChild={
                                <Tooltip title={chosenAddresses.length > 0 ? "" : "Need at least one address in order to load portfolio"}>
                                    <span>
                                        <Button
                                            variant="contained"
                                            onClick={loadPortfolioData}
                                            disabled={chosenAddresses.length === 0 || latestFarmData.length === 0 || loading}
                                        >
                                            Load Data
                                        </Button>
                                    </span>
                                </Tooltip>
                            }
                        />
                    </Grid>
                </Grid>
                { // We add a condition here that means nothing is displayed initially, and the once loading we'll have the usual skeleton place-fillers
                    userDataAgg.length === 0 && !loading ? '' :
                        <Grid container spacing={3} sx={{ mb: 5 }} alignItems="center">
                            <Grid item xs={12} sm={5} md={4} lg={3}>
                                <UserPortfolioCard
                                    latestData={userDataAgg.length > 0 ? userDataAgg[userDataAgg.length - 1] : {}}
                                    loading={loading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7} md={8} lg={9}>
                                <ChartCardWithModal
                                    chartType="portfolio"
                                    chartHeight={350}
                                    initialLookBack={-1}
                                    title="Portfolio"
                                    data={userDataAgg.map(({ dateTimestamp, userDepositTokenBalanceUSD, cumulativeNetDepositsUSDAdjusted, cumulativeNetDepositsUSD }) => (
                                        {
                                            dateTimestamp,
                                            "Portfolio value": userDepositTokenBalanceUSD,
                                            "Net Deposits (USD)": cumulativeNetDepositsUSDAdjusted,
                                            "Net Deposits (past value)": cumulativeNetDepositsUSD,
                                        }
                                    ))}
                                    yAxisLineData="Portfolio value"
                                    yAxisLineData2="Net Deposits (USD)"
                                    yAxisSteppedLineData="Net Deposits (past value)"
                                    xAxisData="dateTimestamp"
                                    valueFormat={currencyRounded}
                                    handleUpdateData={handleUpdateAggData}
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center" alignItems="center">
                                <Divider sx={{
                                    width: '100%',
                                    "&::before, &::after": {
                                        borderColor: "primary.main",
                                    },
                                }}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography variant="subtitle2">
                                            YOUR FARMS
                                        </Typography>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={hideOldFarms}
                                                        onChange={() => setHideOldFarms(!hideOldFarms)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        size="small"
                                                    />
                                                }
                                                label={<Typography variant="subtitle2">Hide old farms</Typography>}

                                            />
                                        </FormGroup>
                                    </Stack>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                {userDataAgg.length > 0 ? userData.filter(
                                    f => !hideOldFarms || f.userDepositTokenBalanceUSD > 1 || (f.userDepositTokenBalance > 0 && !f.depositTokenPrice)
                                ).map((farm, index) => (
                                    <PortfolioFarmAccordion
                                        key={farm.farmAddress}
                                        data={farm}
                                        handleUpdateData={() => handleUpdateData(index)}
                                    />
                                )
                                ) : <Skeleton variant="rectangular" width="100%" height={350} />
                                }
                            </Grid>
                        </Grid>
                }
            </Container>
        </Box >
    )
};