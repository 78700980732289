import { Avatar, AvatarGroup } from "@mui/material";

export const TokenAvatar = (props) => {
    const { tokenType, tokenInfo, size = 25 } = props;
    if (tokenType === 'standardLP') {
        return (
            <AvatarGroup>
                <Avatar src={tokenInfo.token0images.large} sx={{ bgcolor: 'white', width: size, height: size }} />
                <Avatar src={tokenInfo.token1images.large} sx={{ bgcolor: 'white', width: size, height: size }} />
            </AvatarGroup>
        )
    } else if (tokenType === 'coingecko') {
        return (
            <Avatar src={tokenInfo.tokenimages.large} sx={{ bgcolor: 'white', width: size, height: size }} />
        )
    } else {
        return (
            <Avatar sx={{ width: size, height: size }}>$</Avatar>
        )
    }
};
