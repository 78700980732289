import { Area, AreaChart, Bar, Brush, ComposedChart, Legend, Line, linearGradient, stop, defs, LineChart, ReferenceLine, Tooltip, XAxis, YAxis, BarChart, Cell, LabelList } from "recharts";
import * as React from 'react';
import { useTheme } from "@emotion/react";

export const GenericChart = (props) => {
    const { chartWidth, chartHeight, fontSize, chartData, otherChartProps, animationActive, brushIndex, handleBrushIndexChange, isMiniChart } = props;
    const { chartType, title, data, extraData, xAxisData, yAxisLineData, yAxisLineData2, yAxisSteppedLineData, yAxisBarData1, yAxisBarData2, valueFormat, colorLine, colorBar1, colorBar2 } = otherChartProps;
    const theme = useTheme();
    let labelColor = theme.palette.text.main;
    let backgroundColor = theme.palette.background.default;
    let fillBar1 = theme.palette.primary.main;
    let fillBar2 = theme.palette.error.main;
    let strokeLine = chartType !== 'combo' ? theme.palette.primary.main : theme.palette.info.main;
    if (colorBar1) {
        fillBar1 = colorBar1;
    };
    if (colorBar2) {
        fillBar2 = colorBar2;
    };
    if (colorLine) {
        strokeLine = colorLine;
    };

    const margins = isMiniChart ? { top: 0, right: 10, left: 0, bottom: 0 } : { top: 10, right: 40, left: 5, bottom: 5 };

    const timestampToDate = timestamp => new Date(1000 * timestamp).toISOString().slice(0, 10);

    // Some components are shared between different chart types, so we define them here for use below (instead of duplicating).
    const ourLine = <Line yAxisId={chartType === 'combo' ? "right" : "left"} isAnimationActive={animationActive} type="monotone" dot={false} dataKey={yAxisLineData} stroke={strokeLine} />;
    const ourLine2 = <Line yAxisId="left" isAnimationActive={animationActive} type="monotone" dot={false} dataKey={yAxisLineData2} stroke={theme.palette.info.main} />;
    const ourSteppedLine = <Line yAxisId="left" isAnimationActive={animationActive} type="stepAfter" dot={false} dataKey={yAxisSteppedLineData} stroke={theme.palette.text.disabled} />;
    const ourBar = (dataKey, fill) => <Bar yAxisId="left" stackId="a" isAnimationActive={animationActive} dataKey={dataKey} fill={fill} />;
    const ourBar1 = <Bar yAxisId="left" stackId="a" isAnimationActive={animationActive} dataKey={yAxisBarData1} fillOpacity={1} fill="url(#colorBar1)" />;
    const ourBar2 = <Bar yAxisId="left" stackId="a" isAnimationActive={animationActive} dataKey={yAxisBarData2} fillOpacity={1} fill="url(#colorBar2)" />;
    const ourXAxis = <XAxis tick={!isMiniChart} height={isMiniChart ? 5 : 30} axisLine={chartType === 'line'} domain={chartType === 'combo' || chartType === 'stacked bar' ? ['dataMin - 43200', 'dataMax + 43200'] : ['auto', 'auto']} dataKey={xAxisData} type="number" scale='time' tickFormatter={timestampToDate} style={{ fontSize: "0.8rem" }} />;
    const ourLeftYAxis = <YAxis tick={!isMiniChart} width={isMiniChart ? 2 : 60} yAxisId="left" orientation="left" tickFormatter={v => valueFormat(v)} style={{ fontSize: fontSize }} />;
    const ourRightYAxis = <YAxis yAxisId="right" orientation="right" tickFormatter={v => valueFormat(v)} style={{ fontSize: fontSize }} stroke={strokeLine} domain={[-1, 'auto']} />;
    const ourBrush = <Brush dataKey={xAxisData} tickFormatter={timestampToDate} fill={backgroundColor} height={isMiniChart ? 5 : 15} startIndex={chartData.length - Math.min(brushIndex.startIndex, chartData.length)} endIndex={chartData.length - 1 - Math.min(brushIndex.endIndex, chartData.length - 1)} onChange={handleBrushIndexChange} />;
    const ourToolTip = (formatter) => <Tooltip formatter={formatter} labelFormatter={timestampToDate} contentStyle={{ backgroundColor: backgroundColor, opacity: 0.75 }} labelStyle={{ color: labelColor }} />;

    const ourLinearGradient = (
        <defs>
            <linearGradient id="colorLine" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={strokeLine} stopOpacity={0.25} />
                <stop offset="90%" stopColor={strokeLine} stopOpacity={0} />
            </linearGradient>
        </defs>
    );
    const ourBarLinearGradient = (
        <defs>
            <linearGradient id="colorBar1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={fillBar1} stopOpacity={1} />
                <stop offset="90%" stopColor={fillBar1} stopOpacity={0.25} />
            </linearGradient>
            <linearGradient id="colorBar2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={fillBar2} stopOpacity={0.25} />
                <stop offset="90%" stopColor={fillBar2} stopOpacity={1} />
            </linearGradient>
        </defs>
    );
    const ourArea = <Area yAxisId="left" isAnimationActive={animationActive} type="monotone" dot={false} dataKey={yAxisLineData} stroke={strokeLine} fillOpacity={1} fill="url(#colorLine)" />

    if (chartType === 'line') {
        return (
            <AreaChart data={chartData} margin={margins} width={chartWidth} height={chartHeight}>
                {ourLinearGradient}
                {ourArea}
                {ourXAxis}
                {ourLeftYAxis}
                {ourToolTip((value, name, props) => [valueFormat(value), title])}
                {ourBrush}
            </AreaChart>
        )
    } else if (chartType === 'combo') {
        return (
            <ComposedChart stackOffset="sign" data={chartData} margin={margins} width={chartWidth} height={chartHeight}>
                {ourBarLinearGradient}
                {ourBar1}
                {ourBar2}
                {/* {ourBar(yAxisBarData1, fillBar1)}
                {ourBar(yAxisBarData2, fillBar2)} */}
                {ourLine}
                {ourXAxis}
                <ReferenceLine y={0} yAxisId="left" />
                <ReferenceLine y={0} yAxisId="right" stroke={strokeLine} strokeDasharray="3 3" />
                {ourLeftYAxis}
                {ourRightYAxis}
                {ourToolTip(v => valueFormat(v))}
                {/* <Legend verticalAlign="top" iconSize="8" /> */}
                {ourBrush}
            </ComposedChart>
        )
    } else if (chartType === 'portfolio') {
        return (
            <LineChart data={chartData} margin={margins} width={chartWidth} height={chartHeight}>
                {ourSteppedLine}
                {yAxisLineData2 && ourLine2}
                {ourLine}
                {ourXAxis}
                <ReferenceLine y={0} yAxisId="left" />
                {ourLeftYAxis}
                {ourToolTip(v => valueFormat(v))}
                <Legend verticalAlign="bottom" height={10} iconSize="5" iconType="square" wrapperStyle={{ fontSize: fontSize }} />
                {ourBrush}
            </LineChart>
        )
    } else if (chartType === 'horizontal bar') {
        return (
            <BarChart layout="vertical" data={chartData} margin={{ top: 10, right: 50, left: 140, bottom: 5 }} width={chartWidth} height={chartHeight}>
                <XAxis height={30} type="number" tickFormatter={v => valueFormat(v)} style={{ fontSize: "0.8rem" }} />
                <YAxis tick={false} dataKey={yAxisBarData1} width={60} type="category" interval={0} style={{ fontSize: fontSize }} />
                <Bar isAnimationActive={animationActive} dataKey={xAxisData} fill={fillBar1}>
                    {
                        chartData.map((dataPoint, num) => (<Cell key={num} fill={dataPoint.adapterGroupInfo ? dataPoint.adapterGroupInfo.color : fillBar1} />))
                    }
                    <LabelList dataKey={xAxisData} position="right" formatter={v => valueFormat(v)} />
                    <LabelList dataKey={yAxisBarData1} position="left" style={{ fontSize: fontSize }} />
                </Bar>
                <Tooltip cursor={false} formatter={v => valueFormat(v)} contentStyle={{ backgroundColor: backgroundColor, opacity: 0.75 }} labelStyle={{ color: labelColor }} />;
            </BarChart>
        )
    } else if (chartType === 'stacked area') {
        return (
            <AreaChart data={chartData} margin={margins} width={chartWidth} height={chartHeight}>
                {
                    extraData.map(({ adapterAddress, adapterName, adapterGroupName, adapterGroupInfo }) => (
                        <Area key={adapterAddress || adapterGroupName} yAxisId="left" stackId="a" isAnimationActive={animationActive} type="linear" dot={false} dataKey={adapterAddress || adapterGroupName} name={adapterName || adapterGroupName} stroke={adapterGroupInfo.color} fillOpacity={0.75} fill={adapterGroupInfo.color} />
                    )).reverse()
                }
                {ourXAxis}
                {ourLeftYAxis}
                <Tooltip formatter={v => valueFormat(v)} itemSorter={(item) => extraData.findIndex(d => (d.adapterAddress || d.adapterGroupName) === item.dataKey)} labelFormatter={timestampToDate} contentStyle={{ backgroundColor: backgroundColor, opacity: 0.75, fontSize: 12, lineHeight: 1.1 }} labelStyle={{ color: labelColor }} />;
                {ourBrush}
            </AreaChart>
        )
    } else if (chartType === 'stacked bar') {
        return (
            <BarChart data={chartData} margin={margins} width={chartWidth} height={chartHeight}>
                {
                    extraData.map(({ key, label, color }) => (
                        <Bar key={key} yAxisId="left" stackId="a" isAnimationActive={animationActive} dot={false} dataKey={key} name={label} fillOpacity={0.75} fill={color} />
                    )).reverse()
                }
                {ourXAxis}
                <YAxis domain={[0, 10500]} ticks={[2500, 5000, 7500, 10000]} width={isMiniChart ? 2 : 60} yAxisId="left" orientation="left" tickFormatter={v => valueFormat(v)} style={{ fontSize: fontSize }} />
                <Tooltip cursor={{ opacity: 0.25 }} formatter={v => valueFormat(v)} itemSorter={(item) => extraData.findIndex(d => d.key === item.dataKey)} labelFormatter={timestampToDate} contentStyle={{ backgroundColor: backgroundColor, opacity: 0.75, fontSize: 12, lineHeight: 1.1 }} labelStyle={{ color: labelColor }} />;
                {ourBrush}
            </BarChart>
        )
    }
};